import styled from "styled-components";
import { IPointsGain, IHeroImage } from "./type";

const Styles = {
    Wrapper: styled.div`
        display: flex;
        flex-direction: row;
        position: relative;
        width: 100%;
        height: 70%;
    `,
    Col40: styled.div`
        position: relative;
        display: flex;
        flex-direction: column;
        width: 40%;
        height: 100%;
        justify-content: flex-end;
    `,
    Col60: styled.div`
        position: relative;
        display: flex;
        flex-direction: column;
        width: 60%;
    `,
    Row: styled.div`
        display: flex;
        flex-direction: row;
    `,
    Header: styled.h1`
        color: white;
        font-size: 4vw;
        margin: 0 0 1% 0;
        text-shadow: 4px 4px 3px rgba(50, 0, 50, 1);
        letter-spacing: 0.3vw;
    `,
    Icon: styled.div`
        width: auto;
        height: 6vw;
        display: flex;
        justify-content: center;
        & > img {
            object-fit: contain;
        }
    `,
    NavText: styled.div`
        /* font-family: Futura;
        letter-spacing: 1px; */
        color: white;
        display: flex;
        align-items: center;
        margin-left: 2%;
        margin-right: 4%;
        font-weight: 400;
        text-shadow: 2px 2px 1px rgba(50, 0, 50, 1);
        font-size: 2.2vw;
    `,
    DoubleNavTextContainer: styled.div`
        display: flex;
        flex-flow: column;
        align-items: center;
        justify-content: center;
        margin-left: 2%;
        margin-right: 4%;
    `,
    DoubleNavText: styled.div`
        color: white;
        /* display: flex; */
        /* flex-flow: column; */
        /* align-items: center; */
        margin-left: 2%;
        margin-right: 4%;
        font-weight: 400;
        text-shadow: 2px 2px 1px rgba(50, 0, 50, 1);
        font-size: 2.2vw;
    `,
    HeroImage: styled.div<IHeroImage>`
        height: 100%;
        position: relative;
        z-index: 40;
        & > img {
            top: ${(props) => props.top}%;
            right: ${(props) => props.right}%;
            position: absolute;
            width: ${(props) => props.width}%;
            z-index: 50;
        }
    `,
    PointsGain: styled.img<IPointsGain>`
        position: fixed;
        bottom: -32.5vh;
        z-index: 1000;
        height: 45vw;
        width: 45vw;
        display: ${({ active }) => (active === true ? "flex" : "none")};
        /* left: 36vw; */
    `,
};

export default Styles;
