//import React, { useContext } from "react";
import ImagesData from "../../../data/assets/images/images";
import ScreenBackground from "../../templates/ScreenBackground";
import useTotalPlaytime from "./hook";
import Styles from "./styles";

const {
    ScreenWrapper,
    Content,
    Shadow,
    Star,
    ScreenNameWrapper,
    TitleWrapper,
    PointsText,
    ImageWithSize,
    ImageWithPos,
    PlayerName,
} = Styles;

const {
    shadow,
    logoCMK,
    starfish,
    clock,
    totalPlayTimeTextTop,
    totalPlayTimeTextBottom,
} = ImagesData;

const TotalPlaytime = () => {
    const { TotalPlaytimeMinutes, PlayerNickName } = useTotalPlaytime();

    return (
        <Content>
            <ScreenWrapper>
                <ScreenBackground />
                <Shadow src={shadow} />
                <Star src={starfish} />

                <TitleWrapper>
                    <PlayerName>{PlayerNickName.slice(0, 16)}</PlayerName>
                </TitleWrapper>

                <ImageWithPos src={totalPlayTimeTextTop} top={30} size={80} />
                <ImageWithPos src={clock} top={43} left={14} size={22} />
                <ImageWithPos
                    src={totalPlayTimeTextBottom}
                    top={60}
                    size={52}
                />

                <PointsText>{TotalPlaytimeMinutes}</PointsText>

                <ScreenNameWrapper>
                    <ImageWithSize src={logoCMK} size={30} />
                </ScreenNameWrapper>
            </ScreenWrapper>
        </Content>
    );
};

export default TotalPlaytime;
