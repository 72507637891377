const levelNames:string[] = [
    "ADEPT",
    "KADET",
    "ZWIADOWCA",
    "NAWIGATOR",
    "PILOT",
    "TYTAN",
    "MISTRZ",
    "MISTRZ ENERGII",
    "MISTRZ WIEDZY",
    "MISTRZ BOHATERSTWA",
    "MISTRZ MOCY",
    "MISTRZ ODWAGI",
    "MISTRZ SIŁY",
    "MISTRZ SPRYTU",
    "MISTRZ ARENY",
    "MISTRZ PRZYMIERZA",
    "MISTRZ_SKOCZNOSCI",
    "MISTRZ_ZWINNOSCI",
    "MISTRZ_SZYBKOSCI",
    "MISTRZ_TECHNOLOGII"
]

const GetLevelName = (level: number):string => {
    if (level - 1 >= levelNames.length)
        return levelNames[levelNames.length - 1]
    return levelNames[level - 1]
};

const GetNextLevelName = (level: number):string => {
    if (level >= levelNames.length - 1) {
        return levelNames[levelNames.length - 1]
    }
    return levelNames[level]
};

export { 
    GetLevelName, GetNextLevelName
 }

