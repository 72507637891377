import { useEffect, useContext } from "react";
import { useLocation, useHistory } from "react-router";
import { AppContext } from "../../App";

const useAnimatorAtKiosk = (currentPath: string) => {
    const { dispatchAppData } = useContext(AppContext);
    const { pathname } = useLocation();
    const history = useHistory();

    useEffect(() => {
        if (currentPath.includes("/animator-at-kiosk")) {
            setTimeout(() => {
                dispatchAppData({ gifTrigger: true });
            }, 3800);
            setTimeout(() => {
                history.push(pathname.replace("/animator-at-kiosk", ""));
            }, 4000);
        } else if (currentPath.includes("/animator-away")) {
            setTimeout(() => {
                dispatchAppData({ gifTrigger: true });
            }, 3800);
            setTimeout(() => {
                history.push(pathname.replace("/animator-away", ""));
            }, 4000);
        }
    }, []);

    return {};
};

export default useAnimatorAtKiosk;
