import { useContext } from "react";
import { useHistory, useLocation } from "react-router";
import { useState, useEffect } from "react";
import { AppContext } from "../../App";

const INTERVAL = 1000;

const useComeBackTimer = () => {
    const { pathname } = useLocation();
    const history = useHistory();

    // Safety mechanism
    const {
        appData: { testPlayerAtKiosk },
    } = useContext(AppContext);
    if (typeof testPlayerAtKiosk.data === "undefined") {
        history.push(pathname.replace("/come-back-counter", ""));
        window.location.reload();
    }

    const {
        appData: { timeLeftToPlay },
        dispatchAppData,
    } = useContext(AppContext);

    const [secondsLeft, setSecondsLeft] = useState(
        timeLeftToPlay > 10 ? timeLeftToPlay : 10
    );
    const [timeLeft, setTimeLeft] = useState("");

    useEffect(() => {
        Count(setSecondsLeft, dispatchAppData, history, pathname);
    }, []);

    useEffect(() => {
        ConvertSecondsToString(secondsLeft, setTimeLeft);
    }, [secondsLeft]);

    return { timeLeft };
};

const Count = (
    setSeconds: Function,
    dispatchAppData: Function,
    history: any,
    pathname: string
) => {
    const countInterval = setInterval(() => {
        setSeconds((sec: number) => sec - 1);
    }, INTERVAL);
    setTimeout(() => {
        clearInterval(countInterval);
    }, 5000);
    setTimeout(() => {
        dispatchAppData({ gifTrigger: true });
    }, 5500);
    setTimeout(() => {
        history.push(pathname.replace("/come-back-counter", ""));
    }, 5700);
};

const ConvertSecondsToString = (
    secondsLeft: number,
    setTimeString: Function
) => {
    const minutes = Math.floor(secondsLeft / 60);
    const seconds = secondsLeft % 60;

    const stringSeconds =
        seconds >= 10 ? seconds.toString() : "0" + seconds.toString();

    const timeLeft = minutes.toString() + ":" + stringSeconds;

    setTimeString(timeLeft);
};

export default useComeBackTimer;
