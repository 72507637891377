import { useContext, useEffect, useState } from "react";
import { BarContext } from "..";
import ImagesData from "../../../../data/assets/images/images";
import { GetLevelPair } from "../../../../data/assets/images/levelImages";
import { Styles } from "./styles";

const { Wrapper, LevelAnim, IconAnim, LevelNumberAnim, LevelNameAnim } = Styles;
const { nextLevelAnim, GetLevelIcon } = ImagesData;

const NextLevelAnimation = () => {
    const [levelNamePair, setLevelNamePair] = useState({
        levelNr: "",
        name: "",
    });
    const [active, setActive] = useState(false);

    const {
        barData: { playerLevel, nextLevelName },
    } = useContext(BarContext);

    useEffect(() => {
        setLevelNamePair(GetLevelPair(playerLevel, nextLevelName));
        setTimeout(() => {
            setActive(true);
        }, 2000);
    }, [playerLevel]);

    return (
        <Wrapper>
            {active && (
                <LevelNumberAnim>
                    <img src={levelNamePair.levelNr} alt="lvl number" />
                </LevelNumberAnim>
            )}

            <LevelAnim>
                <img src={nextLevelAnim + "?x=qwe"} alt="new level anim" />
            </LevelAnim>
            <IconAnim>
                <img
                    src={GetLevelIcon(playerLevel) + "?x=qwe"}
                    alt="new level icon"
                />
            </IconAnim>
            {active && (
                <LevelNameAnim>
                    <img src={levelNamePair.name} alt="lvl name" />
                </LevelNameAnim>
            )}
        </Wrapper>
    );
};

export default NextLevelAnimation;
