import React from "react";
import ImagesData from "../../../../data/assets/images/images";
import ScreenBackground from "../../../templates/ScreenBackground";
import { Styles } from "./styles";

const { Wrapper, Frame, Shadow, Star } = Styles;
const { frame, shadow, starfish } = ImagesData;

const NextLevelScreen = () => {
    return (
        <Wrapper displayReward={true}>
            <ScreenBackground />
            <Frame src={frame} />
            <Shadow src={shadow} />
            <Star src={starfish} />
        </Wrapper>
    );
};

export default NextLevelScreen;
