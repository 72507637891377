import { keyframes } from "styled-components";

export const iconAnimation = keyframes`
    0%{
        transform: scale(0)
    }
    65%{
        top: 60%;
        transform: scale(0)
    }
    100%{
        top: 27%;
        transform: scale(1)
    }
`;

export const levelNameAnim = keyframes`
    0%{
        opacity: 0;
        transform: scale(2);
    }
    100%{
        opacity: 1;
        transform: scale(1);
    }
`;
