import styled from "styled-components";
import { IImageSize, IRankingBar, IPointsText } from "./types";

const Styles = {
    ScreenWrapper: styled.div`
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100vw;
        height: 100vh;
        max-width: calc(100vh * 0.576);
        max-height: calc(100vw * 1.74);
    `,
    Content: styled.div`
        width: 100vw;
        height: 100vh;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
    `,
    Shadow: styled.img`
        width: 50%;
        height: 3%;
        position: absolute;
        left: 25%;
        bottom: 2%;
        z-index: 15;
    `,

    TitleWrapper: styled.div`
        top: -2%;
        position: absolute;
        width: 100%;
        height: 18%;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 20;
    `,
    ScreenNameWrapper: styled.div`
        bottom: 0%;
        position: absolute;
        width: 100%;
        height: 15%;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 20;
    `,
    ImageWithSize: styled.img<IImageSize>`
        width: ${(props) => props.size}%;
    `,
    PointsText: styled.div<IPointsText>`
        position: absolute;
        font-size: ${(props) => props.fontSize}vh;
        /* font-size: 2vh; */
        color: purple;
        left: ${(props) => props.grow}%;
        /* text-shadow: 3px 3px 0px rgba(10, 10, 10, 1); */
        /* border: 5px solid #ffffff; */
    `,

    RankingContainer: styled.div`
        position: absolute;
        width: 90%;
        height: 75%;
        display: flex;
        flex-flow: column;
        justify-content: flex-start;
        align-items: center;
        z-index: 100;
    `,
    RankingBar: styled.div<IRankingBar>`
        position: relative;
        background-color: ${(props) => props.bgColor};
        width: 100%;
        height: 5%;
        display: flex;
        justify-content: flex-start;
        align-content: center;
        align-items: center;
        padding-left: 2%;
        z-index: 100;
    `,
    Diamond: styled.img`
        position: absolute;
        width: 5%;
        margin-right: 1%;
        left: 58%;
    `,
    CoinIcon: styled.img`
        position: absolute;
        left: 81%;
        width: 5%;
        margin-right: 1%;
    `,
};

export default Styles;
