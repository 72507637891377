import styled from "styled-components";
import imgFlash from "./keyframes";
import { IImgFlash } from "./types";

const Styles = {
    AnimationContainer: styled.div`
    //background-color: black;
        z-index: 2100;
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
    `,
    AnimatedImage: styled.img<IImgFlash>`
        visibility: hidden;
        animation: ${imgFlash} 0.05s;
        animation-delay: ${({ delay }) => delay};
        position: absolute;
        width: 100%;
        height: 100vh;
        left: 0;
        top: 0;
        z-index: 2100;
    `,
};

export default Styles;
