import { keyframes } from "styled-components";

const bandAnimation = keyframes`
    0% {
        transform:translateX(4%)
    }
    50%{
        transform:translateX(-6%)
    }
    100%{
        transform:translateX(4%)
    }
`;

export { bandAnimation };
