import React, { useEffect, useState } from "react";
import { IMazeScreenFlash } from "./types";
import Styles from "./styles";
import ImagesData from "../../../data/assets/images/images";
import {
    mazeFlashSpots11,
    mazeFlashSpots12,
} from "./data";

const {
    AnimationContainer,
    AnimationImageOptimized,
} = Styles;

const {
    optimizedMazeFlash,
} = ImagesData;

const MazeScreenFlash = ({
    active,
    currentMazeSpot,
    mazeSpotsCount,
}: IMazeScreenFlash) => {
    const [pos, setPos] = useState("left-top");
    // const [right, setRight] = useState("0%");
    // const [bottom, setBottom] = useState("0%");
    const [left, setLeft] = useState("0%");
    const [top, setTop] = useState("0%");

    useEffect(() => {
        if (currentMazeSpot > 0 && currentMazeSpot < 12) {
            const { pos, left, top } =
                mazeSpotsCount === 12
                    ? 
                      mazeFlashSpots12[currentMazeSpot]
                    : 
                      mazeFlashSpots11[currentMazeSpot];
           
            setPos(pos);
            setLeft(left);
            setTop(top);
        }
    }, [currentMazeSpot]);


    const currentSpotFlashImg = (pos: string) => {
       
        switch (pos) {
            case "left-top":
                return "scaleY(-1)";
            case "left-bottom":
                return "scaleX(1)";
            case "right-top":
                return "scaleX(-1) scaleY(-1)";
            case "right-bottom":
                return "scaleX(-1)";
            default:
                return "scaleX(1)";
        }
    };

    if (active) {
        return (
            <AnimationContainer>
                <AnimationImageOptimized
                    src={optimizedMazeFlash}
                    transform={currentSpotFlashImg(pos)}
                    alt="loading..."
                    left={left}
                    top={top}
                />     
            </AnimationContainer>
        );
    } else {
        return <React.Fragment></React.Fragment>;
    }
};

export default MazeScreenFlash;
