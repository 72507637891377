import { useState, useContext } from "react";
import { AppContext } from "../../../App";
import Styles from "./styled";
import ImagesData from "../../../../data/assets/images/images";
import Hand from "./Hand";
import { kiosksLookupTable } from "./data";

const { ContentWrapper, Star, KioskWrapper, Kiosk } = Styles;
const { starfish } = ImagesData;

const Content = () => {
    const {
        appData: {
            infoData: {
                data: {
                    data: [element],
                },
            },
        },
    } = useContext(AppContext);
    const { attraction_id } = element;

    const [kiosk] = useState(kiosksLookupTable[attraction_id]);
    return (
        <ContentWrapper>
            <Star src={starfish} />
            <KioskWrapper>
                <Kiosk src={kiosk} />
            </KioskWrapper>
            <Hand />
        </ContentWrapper>
    );
};

export default Content;
