import styled from "styled-components";

const Styles = {
    Frame: styled.img`
        position: absolute;
        z-index: 20;
        width: 90%;
        height: 80%;
    `,
    Shadow: styled.img`
        width: 90%;
        height: auto;
        position: absolute;
        left: 5%;
        bottom: 0;
        z-index: 15;
    `,
    HeroesImage: styled.img`
        width: 66%;
        height: auto;
        position: absolute;
        left: 17.5%;
        bottom: 20%;
        z-index: 20;
    `,
    Star: styled.img`
        position: absolute;
        width: 30%;
        z-index: 20;
        bottom: 18%;
    `,
};

export default Styles;
