import styled from "styled-components";

const Styles = {
    Coin: styled.img`
        position: absolute;
        width: 20%;
        height: 35%;
        z-index: 30;
    `,
    CoinAnim: styled.img`
        position: absolute;
        width: 35%;
        height: 55%;
        z-index: 35;
    `,
};

export default Styles;
