import level1 from "./levelNames/LEVEL1.png";
import level2 from "./levelNames/LEVEL2.png";
import level3 from "./levelNames/LEVEL3.png";
import level4 from "./levelNames/LEVEL4.png";
import level5 from "./levelNames/LEVEL5.png";
import level6 from "./levelNames/LEVEL6.png";
import level7 from "./levelNames/LEVEL7.png";
import level8 from "./levelNames/LEVEL8.png";
import level9 from "./levelNames/LEVEL9.png";
import level10 from "./levelNames/LEVEL10.png";
import level11 from "./levelNames/LEVEL11.png";
import level12 from "./levelNames/LEVEL12.png";
import level13 from "./levelNames/LEVEL13.png";
import level14 from "./levelNames/LEVEL14.png";
import level15 from "./levelNames/LEVEL15.png";
import level16 from "./levelNames/LEVEL16.png";
import level17 from "./levelNames/LEVEL17.png";
import level18 from "./levelNames/LEVEL18.png";
import level19 from "./levelNames/LEVEL19.png";
import level20 from "./levelNames/LEVEL20.png";

import ADEPT from "./levelNames/ADEPT.png";
import KADET from "./levelNames/KADET.png";
import MISTRZ_ARENY from "./levelNames/MISTRZ_ARENY.png";
import MISTRZ_BOHATERSTWA from "./levelNames/MISTRZ_BOHATERSTWA.png";
import MISTRZ_ENERGII from "./levelNames/MISTRZ_ENERGII.png";
import MISTRZ_MOCY from "./levelNames/MISTRZ_MOCY.png";
import MISTRZ_ODWAGI from "./levelNames/MISTRZ_ODWAGI.png";
import MISTRZ_PRZYMIERZA from "./levelNames/MISTRZ_PRZYMIERZA.png";
import MISTRZ_SILY from "./levelNames/MISTRZ_SILY.png";
import MISTRZ_SPRYTU from "./levelNames/MISTRZ_SPRYTU.png";
import MISTRZ from "./levelNames/MISTRZ.png";
import NAWIGATOR from "./levelNames/NAWIGATOR.png";
import PILOT from "./levelNames/PILOT.png";
import TYTAN from "./levelNames/TYTAN.png";
import ZWIADOWCA from "./levelNames/ZWIADOWCA.png";
import MISTRZ_SKOCZNOSCI from "./levelNames/MISTRZ_SKOCZNOSCI.png";
import MISTRZ_ZWINNOSCI from "./levelNames/MISTRZ_ZWINNOSCI.png";
import MISTRZ_SZYBKOSCI from "./levelNames/MISTRZ_SZYBKOSCI.png";
import MISTRZ_TECHNOLOGII from "./levelNames/MISTRZ_TECHNOLOGII.png";


const levels = [
    level1,
    level2,
    level3,
    level4,
    level5,
    level6,
    level7,
    level8,
    level9,
    level10,
    level11,
    level12,
    level13,
    level14,
    level15,
    level16,
    level17,
    level18,
    level19,
    level20,
];

const levelNames = [
    { name: "ADEPT", img: ADEPT },
    { name: "KADET", img: KADET },
    { name: "MISTRZ ARENY", img: MISTRZ_ARENY },
    { name: "MISTRZ BOHATERSTWA", img: MISTRZ_BOHATERSTWA },
    { name: "MISTRZ ENERGII", img: MISTRZ_ENERGII },
    { name: "MISTRZ MOCY", img: MISTRZ_MOCY },
    { name: "MISTRZ ODWAGI", img: MISTRZ_ODWAGI },
    { name: "MISTRZ PRZYMIERZA", img: MISTRZ_PRZYMIERZA },
    { name: "MISTRZ SILY", img: MISTRZ_SILY },
    { name: "MISTRZ SPRYTU", img: MISTRZ_SPRYTU },
    { name: "MISTRZ", img: MISTRZ },
    { name: "NAWIGATOR", img: NAWIGATOR },
    { name: "PILOT", img: PILOT },
    { name: "TYTAN", img: TYTAN },
    { name: "ZWIADOWCA", img: ZWIADOWCA },
    { name: "MISTRZ_SKOCZNOSCI", img: MISTRZ_SKOCZNOSCI },
    { name: "MISTRZ_ZWINNOSCI", img: MISTRZ_ZWINNOSCI },
    { name: "MISTRZ_SZYBKOSCI", img: MISTRZ_SZYBKOSCI },
    { name: "MISTRZ_TECHNOLOGII", img: MISTRZ_TECHNOLOGII },
];

export const GetLevelPair = (levelNumber: number, levelName: string) => {
    if (levelNumber > levels.length - 1) {
        return {
            levelNr: levels[levels.length - 1],
            name: levelNames.slice(-1)[0].img,
        };
    }
    const levelNr = levels[levelNumber - 1];
    const name = levelNames.find((el) => el.name === levelName)?.img ?? ADEPT;
    return { levelNr, name };
};
