import sf1 from "./Transition_V02_Energy_116_Radial_Out_Hit_0000000001.png";
import sf2 from "./Transition_V02_Energy_116_Radial_Out_Hit_0000000002.png";
import sf3 from "./Transition_V02_Energy_116_Radial_Out_Hit_0000000003.png";
import sf4 from "./Transition_V02_Energy_116_Radial_Out_Hit_0000000004.png";
import sf5 from "./Transition_V02_Energy_116_Radial_Out_Hit_0000000005.png";
import sf6 from "./Transition_V02_Energy_116_Radial_Out_Hit_0000000006.png";
import sf7 from "./Transition_V02_Energy_116_Radial_Out_Hit_0000000007.png";
import sf8 from "./Transition_V02_Energy_116_Radial_Out_Hit_0000000008.png";
import sf9 from "./Transition_V02_Energy_116_Radial_Out_Hit_0000000009.png";
import sf10 from "./Transition_V02_Energy_116_Radial_Out_Hit_0000000010.png";
import sf11 from "./Transition_V02_Energy_116_Radial_Out_Hit_0000000011.png";
import sf12 from "./Transition_V02_Energy_116_Radial_Out_Hit_0000000012.png";
import sf13 from "./Transition_V02_Energy_116_Radial_Out_Hit_0000000013.png";
import sf14 from "./Transition_V02_Energy_116_Radial_Out_Hit_0000000014.png";
import sf15 from "./Transition_V02_Energy_116_Radial_Out_Hit_0000000015.png";
import sf16 from "./Transition_V02_Energy_116_Radial_Out_Hit_0000000016.png";

const ScreenFlashImages = {
    sf1,
    sf2,
    sf3,
    sf4,
    sf5,
    sf6,
    sf7,
    sf8,
    sf9,
    sf10,
    sf11,
    sf12,
    sf13,
    sf14,
    sf15,
    sf16,
};

export default ScreenFlashImages;
