import styled, { css } from "styled-components";
import {
    IPointsGain,
    IStampContainer,
    IDiamondIcon,
    IPointsAmount,
} from "./types";
import { mergeStampAnim, pointsAnim, addPointsToBar } from "./keyframes";

const Styles = {
    StampsWrapper: styled.div`
        margin-top: -8%;
        position: relative;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 80%;
        height: 40%;
        z-index: 1000;
    `,
    StampContainer: styled.div<IStampContainer>`
        animation-fill-mode: forwards;
        overflow: hidden;
        position: absolute;
        display: flex;
        width: 12.5%;
        margin: 0% 2% 0% 2%;
        transform: scale3d(2.8, 2.8, 2.8);
        left: ${({ left }) => left + "%"};
        top: ${({ top }) => top + "%"};
        ${({ animActive }) =>
            animActive &&
            css`
                animation: ${mergeStampAnim} 0.5s ease-in-out;
            `}
        ${({ addCoinAnimActive, leftDest, topDest }) =>
            addCoinAnimActive &&
            css`
                animation: ${addPointsToBar({
                        leftDest,
                        topDest,
                    })}
                    1s ease-in-out;
                animation-fill-mode: forwards;
                animation-delay: 0.5s;
            `}
    `,
    Stamp: styled.img`
        position: relative;
        width: 100%;
        transition: 1s;
    `,
    Shadow: styled.img`
        width: 50%;
        height: 20%;
        position: absolute;
        left: 25%;
        bottom: -25%;
        z-index: 30;
    `,
    PointsGain: styled.img<IPointsGain>`
        visibility: ${({ animActive }) =>
            animActive === true ? "visible" : "hidden"};
        position: absolute;
        top: 0%;
        z-index: 1000;
        height: 100%;
        left: 0%;
    `,
    PointsAmount: styled.div<IPointsAmount>`
        animation: ${({ isAnimationActive }) =>
            isAnimationActive === true
                ? css`
                      ${pointsAnim} 2.3s ease-in-out
                  `
                : "unset"};
        animation-fill-mode: forwards;
        position: fixed;
        color: white;
        font-size: 1.5vw;
        top: 40%;
        z-index: 3000;
        text-shadow: 0.25vw 0.25vw 0px rgba(42, 42, 42, 1);
        height: auto;
        font-weight: 700;
        justify-content: center;
        align-items: center;
        left: 35%;
    `,
    DiamondIcon: styled.img<IDiamondIcon>`
        animation: ${({ isAnimationActive }) =>
            isAnimationActive === true
                ? css`
                      ${pointsAnim} 2.3s ease-in-out
                  `
                : "unset"};
        position: relative;
        z-index: 3000;
        width: 15%;
        height: 15%;
        margin-top: 40%;
        left: -48%;
    `,
};

export default Styles;