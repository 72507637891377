import { useEffect, createContext, useReducer, useState } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import IAppContext from "./types";
import appContext from "./context";
import Head from "../Head";
import ScreenFlash from "../templates/ScreenFlash";
import MazeScreenFlash from "../templates/MazeScreenFlash";
import Routes from "./Routes";
import { ConnectingLabel, LoginBackgroundContainer } from "./styled";
//import ImagesData from "../../data/assets/images/images";
import ScreenBackground from "../templates/ScreenBackground";

const queryClient = new QueryClient();
//const {} = ImagesData;

export const AppContext = createContext<IAppContext>(appContext);

function App() {
    const [appData, dispatchAppData] = useReducer(
        (state: object, payload: any) => ({ ...state, ...payload }),
        appContext.appData
    );
    const {
        gifTrigger,
        mazeGifTrigger,
        xMassMazeGifTrigger,
        currentMazeSpot,
        mazeSpotsCount,
        currentXMassMazeSpot,
        xMassMazeSpotsCount,
        logged,
    } = appData;

    useEffect(() => {
        if (gifTrigger === true) {
            setTimeout(() => dispatchAppData({ gifTrigger: false }), 700);
        }
    }, [gifTrigger]);

    useEffect(() => {
        if (mazeGifTrigger === true) {
            setTimeout(() => dispatchAppData({ mazeGifTrigger: false }), 1100);
        }
    }, [mazeGifTrigger]);

    useEffect(() => {
        if (xMassMazeGifTrigger === true) {
            setTimeout(() => dispatchAppData({ xMassMazeGifTrigger: false }), 1100);
        }
    }, [xMassMazeGifTrigger]);

    const [sseOpen, setSseOpen] = useState(false);
    useEffect(() => {
        if (!sseOpen) {
            const sse = new EventSource("http://localhost:8888/stream");
            setSseOpen(true);

            sse.onmessage = (mess) => {
                dispatchAppData({ RFIDSimulateTrigger: "" });
                const card = mess.data.replace(/\D/g, "");
                dispatchAppData({ RFIDSimulateTrigger: card });
            };

            sse.onerror = (err) => {
                sse.close();
                setTimeout(() => {
                    setSseOpen(false);
                }, 20000);
            };
        }
    }, [sseOpen]);

    const [sse2Open, setSse2Open] = useState(false);
    useEffect(() => {
        if (!sse2Open) {
            const sse2 = new EventSource("http://127.0.0.1:5555/rfid-stream");
            setSse2Open(true);

            sse2.onmessage = (mess) => {
                dispatchAppData({ RFIDSimulateTrigger: "" });
                const card = mess.data.replace(/\D/g, "");
                dispatchAppData({ RFIDSimulateTrigger: card });
            };
            sse2.onerror = (err) => {
                sse2.close();
                setTimeout(() => {
                    setSse2Open(false);
                }, 21000);
            };
        }
    }, [sse2Open]);

    return (
        <>
            <Head />
            <QueryClientProvider client={queryClient}>
                <AppContext.Provider
                    value={{
                        appData,
                        dispatchAppData,
                    }}
                >
                    <ScreenFlash active={gifTrigger} />
                    <MazeScreenFlash
                        active={mazeGifTrigger}
                        currentMazeSpot={currentMazeSpot}
                        mazeSpotsCount={mazeSpotsCount}
                    />
                    {
                        
                        <MazeScreenFlash
                        active={xMassMazeGifTrigger}
                        currentMazeSpot={currentXMassMazeSpot}
                        mazeSpotsCount={xMassMazeSpotsCount}
                    />
                        

                    }
               
                    <Routes />
                    {logged === false && (
                        <ConnectingLabel>CONNECTING...</ConnectingLabel>
                    )}
                    <LoginBackgroundContainer>
                        <ScreenBackground />
                    </LoginBackgroundContainer>
                </AppContext.Provider>
            </QueryClientProvider>
        </>
    );
}

export default App;
