const barContext = {
    barData: {
        startPktAmount: 0,
        coinRewardFlashActive: false,
        coinRewardTrigger: false,
        coinsLeftToGive: 0,
        playerLevel: 1,
        nextLevelName: "",
        headerTextAnimationActive: false,
        headerSecondTextAnimationActive: false,
        headerThirdTextAnimationActive: false,
        headerNextLevelAnimationActive: false,
        nextLevelTrigger: false,
        nextLevelScreen: false,
        nextLevelAnimation: false,
        nextLevelIconFlashTrigger: false,
    },
    dispatchBarData: {},
};

export default barContext;

export interface IBarContext {
    barData: {
        startPktAmount: number;
        coinRewardFlashActive: boolean;
        coinRewardTrigger: boolean;
        coinsLeftToGive: number;
        playerLevel: number;
        nextLevelName: string;
        headerTextAnimationActive: boolean;
        headerSecondTextAnimationActive: boolean;
        headerThirdTextAnimationActive: boolean;
        headerNextLevelAnimationActive: boolean;
        nextLevelTrigger: boolean;
        nextLevelScreen: boolean;
        nextLevelAnimation: boolean;
        nextLevelIconFlashTrigger: boolean;
    };
    dispatchBarData: any;
}
