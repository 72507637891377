import stampOn_1 from "./stampOn_1.png";
import stampOn_2 from "./stampOn_2.png";
import stampOn_3 from "./stampOn_3.png";
import stampOn_4 from "./stampOn_4.png";
import stampOn_5 from "./stampOn_5.png";
import stampOn_6 from "./stampOn_6.png";
import stampOn_7 from "./stampOn_7.png";
import stampOn_8 from "./stampOn_8.png";
import stampOn_9 from "./stampOn_9.png";
import stampOn_10 from "./stampOn_10.png";
import stampOn_11 from "./stampOn_11.png";
import stampOn_12 from "./stampOn_12.png";
import stampOff_1 from "./stampOff_1.png";
import stampOff_2 from "./stampOff_2.png";
import stampOff_3 from "./stampOff_3.png";
import stampOff_4 from "./stampOff_4.png";
import stampOff_5 from "./stampOff_5.png";
import stampOff_6 from "./stampOff_6.png";
import stampOff_7 from "./stampOff_7.png";
import stampOff_8 from "./stampOff_8.png";
import stampOff_9 from "./stampOff_9.png";
import stampOff_10 from "./stampOff_10.png";
import stampOff_11 from "./stampOff_11.png";
import stampOff_12 from "./stampOff_12.png";
import stampAnim_1 from "./pieczatka1.gif";
import stampAnim_2 from "./pieczatka2.gif";
import stampAnim_3 from "./pieczatka3.gif";
import stampAnim_4 from "./pieczatka4.gif";
import stampAnim_5 from "./pieczatka5.gif";
import stampAnim_6 from "./pieczatka6.gif";
import stampAnim_7 from "./pieczatka7.gif";
import stampAnim_8 from "./pieczatka8.gif";
import stampAnim_9 from "./pieczatka9.gif";
import stampAnim_10 from "./pieczatka10.gif";
import stampAnim_11 from "./pieczatka11.gif";
import stampAnim_12 from "./pieczatka12.gif";

const mazeAssets = {
    stampOn_1,
    stampOn_2,
    stampOn_3,
    stampOn_4,
    stampOn_5,
    stampOn_6,
    stampOn_7,
    stampOn_8,
    stampOn_9,
    stampOn_10,
    stampOn_11,
    stampOn_12,
    stampOff_1,
    stampOff_2,
    stampOff_3,
    stampOff_4,
    stampOff_5,
    stampOff_6,
    stampOff_7,
    stampOff_8,
    stampOff_9,
    stampOff_10,
    stampOff_11,
    stampOff_12,
    stampAnim_1,
    stampAnim_2,
    stampAnim_3,
    stampAnim_4,
    stampAnim_5,
    stampAnim_6,
    stampAnim_7,
    stampAnim_8,
    stampAnim_9,
    stampAnim_10,
    stampAnim_11,
    stampAnim_12,
};

export default mazeAssets;
