import  { useContext, useEffect, useState } from "react";
import api from "../../../api/api";
import { AppContext } from "../../App";
import useFetchData from "../../../api/apiHelpers";
import { Player } from "./types";

const REFRESH_TIME = 10000;
const HERO_ID = "-";
const TOP_PLAYERS = 20;

export const useRanking = () => {
    // POBIERANIE DANYCH DO RANKINGU Z API
    const handler = useFetchData();
    const [topPlayers, setTopPlayers] = useState<Player[]>([]);

    const {
        appData: { loginData },
    } = useContext(AppContext);

    const getApiData = () => {
        handler(async () => {
            const response = await api.bestResults(
                loginData.data.token,
                HERO_ID,
                TOP_PLAYERS
            );
            if (response.data.data) {
                // Comment here to undo summing player points
                // const holder: any = {};
                // response.data.data.forEach((player: any) => {
                //     if (holder.hasOwnProperty(player.nickname)) {
                //         holder[player.nickname] = [
                //             holder[player.nickname][0] +
                //                 parseInt(player.noOfPoints),
                //             holder[player.nickname][1] +
                //                 parseInt(player.noOfStars),
                //         ];
                //     } else {
                //         holder[player.nickname] = [
                //             parseInt(player.noOfPoints),
                //             parseInt(player.noOfStars),
                //         ];
                //     }
                // });

                // const top: Player[] = [];
                // for (let prop in holder) {
                //     top.push({
                //         nickname: prop,
                //         noOfPoints: holder[prop][0],
                //         noOfStars: holder[prop][1],
                //     });
                // }

                // setTopPlayers(top);
                // // Up to here

                // Uncomment to not sum
                setTopPlayers(response.data.data);
            }
        });
    };

    useEffect(() => {
        getApiData();
        const interval = setInterval(() => {
            getApiData();
        }, REFRESH_TIME);
        return () => {
            clearInterval(interval);
        };
    }, []);

    return topPlayers;
};
