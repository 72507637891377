import styled from "styled-components";
import { IIconFlashActive, IFontSize } from "./types";

const Styles = {
    LevelInfoContainer: styled.div`
        display: flex;
        flex-flow: row;
        justify-content: space-between;
        margin-top: 1%;
        margin-left: 2%;
        margin-bottom: 1%;
        margin-right: 4%;
    `,
    LeftLevel: styled.div`
        display: flex;
        align-items: center;
        z-index: 75;
        /* border: 3px solid black; */
    `,
    Col: styled.div`
        display: flex;
        flex-flow: column;
        margin: 0% 5%;
    `,
    LevelName: styled.div<IFontSize>`
        color: white;
        /* font-size: 4.5vw; */
        font-size: ${(props) => (props.type === "small" ? 3.5 : 4.5)}vw;
        font-weight: 600;
        /* font-size: 4vw; */
        text-shadow: 5px 5px 5px rgba(42, 0, 42, 1);
    `,
    LevelNumber: styled.div`
        color: white;
        font-size: 1.8vw;
        text-shadow: 3px 3px 3px rgba(42, 0, 42, 1);
    `,
    LevelIcon: styled.div`
        /* width: 100%; */
        position: relative;
        margin-right: 2%;
        /* border: 2px solid black; */
        /* max-height: 50%; */
        & > img {
            /* width: auto; */
            /* object-fit: fit-contain; */
        }
    `,
    NextLevelFlashIcon: styled.img<IIconFlashActive>`
        display: ${(props) => (props.active ? "flex" : "none")};
        position: absolute;
        /* bottom: 10%; */
        top: -370%;
        left: -270%;
        z-index: 100;
        /* border: 2px solid black; */
    `,
};

export default Styles;
