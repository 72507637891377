import React, { useState } from "react";
import ImagesData from "../../../../data/assets/images/images";
import Styles from "./styles";

const {
    GateImg,
    TitleWrapper,
    ScreenTitle,
    Frame,
    Shadow,
    ScreenName,
    ScreenNameWrapper,
    Star,
} = Styles;

const { gateOpen, gateOpenText, frame, shadow, battle, starfish } = ImagesData;

const GateOpen = () => {
    const [animTrigger] = useState("?x=" + Math.random());
    return (
        <>
            <Frame src={frame} />
            <Shadow src={shadow} />
            <Star src={starfish} />
            <ScreenNameWrapper>
                <ScreenName src={battle} />
            </ScreenNameWrapper>
            <GateImg src={gateOpen} />
            <TitleWrapper>
                <ScreenTitle src={gateOpenText + animTrigger} />
            </TitleWrapper>
        </>
    );
};

export default GateOpen;
