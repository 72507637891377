import tree_1_unlocked from "./tree_1_unlocked.png";
import tree_2_unlocked from "./tree_2_unlocked.png";
import tree_3_unlocked from "./tree_3_unlocked.png";
import tree_4_unlocked from "./tree_4_unlocked.png";

import tree_1 from "./tree_1.png";
import tree_2 from "./tree_2.png";
import tree_3 from "./tree_3.png";
import tree_4 from "./tree_4.png";

import tree_1_anim from "./tree_1_anim.gif";
import tree_2_anim from "./tree_2_anim.gif";
import tree_3_anim from "./tree_3_anim.gif";
import tree_4_anim from "./tree_4_anim.gif";


const xMassMazeAssets = {
    tree_1_unlocked,
    tree_2_unlocked,
    tree_3_unlocked,
    tree_4_unlocked,
    tree_1,
    tree_2,
    tree_3,
    tree_4,
    tree_1_anim,
    tree_2_anim,
    tree_3_anim,
    tree_4_anim,
};

export default xMassMazeAssets;
