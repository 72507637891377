import styled from "styled-components";

const Styles = {
    Container: styled.div`
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    `,
    BonusWrapper: styled.div`
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        z-index: 40;
    `,
    BonusQuantity: styled.div`
        color: white;
        font-size: 15vw;
        text-shadow: 8px 8px 5px rgba(110, 0, 110, 1);
    `,
    BonusIcon: styled.img`
        width: 15vw;
    `,
    TimeWrapper: styled.div`
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        margin-top: 3%;
        z-index: 40;
    `,
    TimeIcon: styled.img`
        width: 6.5vw;
        margin-right: 5%;
    `,
    Time: styled.div`
        color: white;
        font-size: 5.5vw;
        text-shadow: 8px 8px 4px rgba(110, 0, 110, 1);
    `,
    Multiplay: styled.div`
        color: white;
        font-size: 7.5vw;
        text-shadow: 8px 8px 5px rgba(110, 0, 110, 1);
    `,
};

export default Styles;
