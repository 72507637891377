import ScreenBackground from "../../templates/ScreenBackground";
import ImagesData from "../../../data/assets/images/images";
import Styles from "./styled";
import Title from "./Title";
import ScreenFrame from "../../templates/ScreenFrame";
import useWelcome from "./hook";

const { Frame, Shadow, HeroesImage, Star } = Styles;
const { frame, shadow, heroes, starfish } = ImagesData;

const Welcome = () => {
    useWelcome();
    return (
        <ScreenFrame>
            <ScreenBackground />
            <Star src={starfish} />
            <Frame src={frame} />
            <Shadow src={shadow} />
            <HeroesImage src={heroes} />
            <Title />
        </ScreenFrame>
    );
};

export default Welcome;
