import React, { useState } from "react";
import Styles from "./styles";
import ImagesData from "../../../../data/assets/images/images";
import ScreenBackground from "../../../templates/ScreenBackground";
import useTokenPayer from "./hook";
const { frame, shadow, starfish, coin, coinFlash } = ImagesData;

const { Star, ScreenWrapper, Content, Frame, Shadow, TokenImg, TokenFlash } =
    Styles;

const TokenPayer = () => {
    const [animTrigger] = useState("?x=" + Math.random());

    useTokenPayer();

    return (
        <Content>
            <ScreenWrapper>
                <Frame src={frame} />
                <Shadow src={shadow} />
                <Star src={starfish} />
                <TokenImg src={coin} />
                <TokenFlash src={coinFlash + animTrigger} />
                <ScreenBackground />
            </ScreenWrapper>
        </Content>
    );
};

export default TokenPayer;
