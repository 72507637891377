import ScreenFlashImages from "../../../data/assets/images/screenFlash";

const {
    sf1,
    sf2,
    sf3,
    sf4,
    sf5,
    sf6,
    sf7,
    sf8,
    sf9,
    sf10,
    sf11,
    sf12,
    sf13,
    sf14,
    sf15,
    sf16,
} = ScreenFlashImages;

const FlashElementsData = [
    {
        id: 1,
        delay: "0.05s",
        image: sf1,
    },
    {
        id: 2,
        delay: "0.10s",
        image: sf2,
    },
    {
        id: 3,
        delay: "0.15s",
        image: sf3,
    },
    {
        id: 4,
        delay: "0.20s",
        image: sf4,
    },
    {
        id: 5,
        delay: "0.25s",
        image: sf5,
    },
    {
        id: 6,
        delay: "0.30s",
        image: sf6,
    },
    {
        id: 7,
        delay: "0.35s",
        image: sf7,
    },
    {
        id: 8,
        delay: "0.40s",
        image: sf8,
    },
    {
        id: 9,
        delay: "0.45s",
        image: sf9,
    },
    {
        id: 10,
        delay: "0.50s",
        image: sf10,
    },
    {
        id: 11,
        delay: "0.55s",
        image: sf11,
    },
    {
        id: 12,
        delay: "0.60s",
        image: sf12,
    },
    {
        id: 13,
        delay: "0.65s",
        image: sf13,
    },
    {
        id: 14,
        delay: "0.70s",
        image: sf14,
    },
    {
        id: 15,
        delay: "0.75s",
        image: sf15,
    },
    {
        id: 16,
        delay: "0.80s",
        image: sf16,
    },
];

export default FlashElementsData;
