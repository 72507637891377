import expressoVideo from "../../../data/assets/videos/expresso-hero.mp4"
import fervenVideo from "../../../data/assets/videos/ferven-hero.mp4"
import flexVideo from "../../../data/assets/videos/flex-hero.mp4"
import nexusVideo from "../../../data/assets/videos/nexus-hero.mp4"
import scandeVideo from "../../../data/assets/videos/scande-hero.mp4"
import umbraVideo from "../../../data/assets/videos/umbra-hero.mp4"
import chimeronVideo from "../../../data/assets/videos/chimeron-hero.mp4"

import expressoIcon from "../../../data/assets/images/parkEnteranceDashboard/expresso_ikona.png"
import fervenIcon from "../../../data/assets/images/parkEnteranceDashboard/ferven_ikona.png"
import flexIcon from "../../../data/assets/images/parkEnteranceDashboard/flex_ikona.png"
import nexusIcon from "../../../data/assets/images/parkEnteranceDashboard/nexus_ikona.png"
import scandeIcon from "../../../data/assets/images/parkEnteranceDashboard/scande_ikona.png"
import umbraIcon from "../../../data/assets/images/parkEnteranceDashboard/umbra_ikona.png"
import chimeronIcon from "../../../data/assets/images/parkEnteranceDashboard/chimeron_ikona.png"

import expressoBody from "../../../data/assets/images/parkEnteranceDashboard/expresso_maly.png"
import fervenBody from "../../../data/assets/images/parkEnteranceDashboard/ferven_maly.png"
import flexBody from "../../../data/assets/images/parkEnteranceDashboard/flex_maly.png"
import nexusBody from "../../../data/assets/images/parkEnteranceDashboard/nexus_maly.png"
import scandeBody from "../../../data/assets/images/parkEnteranceDashboard/scande_mala.png"
import umbraBody from "../../../data/assets/images/parkEnteranceDashboard/umbra_mala.png"
import chimeronBody from "../../../data/assets/images/parkEnteranceDashboard/chimeron_maly.png"

export const heroVideos = {
    'expresso': expressoVideo,
    'ferven': fervenVideo,
    'flex': flexVideo,
    'nexus': nexusVideo,
    'scande': scandeVideo,
    'umbra': umbraVideo,
    'chimeron': chimeronVideo
}

export const heroIcons = {
    'expresso': expressoIcon,
    'ferven': fervenIcon,
    'flex': flexIcon,
    'nexus': nexusIcon,
    'scande': scandeIcon,
    'umbra': umbraIcon,
    'chimeron': chimeronIcon
    
}

export const heroBodies = {
    'expresso': expressoBody,
    'ferven': fervenBody,
    'flex': flexBody,
    'nexus': nexusBody,
    'scande': scandeBody,
    'umbra': umbraBody,
    'chimeron': chimeronBody
}

export const countingGaps = [105,
    110,
    116,
    122,
    128,
    134,
    141,
    148,
    155,
    163,
    171,
    180,
    189,
    198,
    208,
    218,
    229,
    241,
    253,
    265,
    279,
    293,
    307,
    323,
    339,
    356,
    373,
    392,
    412,
    432,
    454,
    476,
    500,
    525,
    552,
    579,
    608,
    639,
    670,
    704,
    739,
    776,
    815,
    856,
    899,
    943,
    991,
    1040,
    1092,
    1147,
    1204,
    1264,
    1327,
1394,
1464,
1537,
    ]