import styled from "styled-components";

const Styles = {
    TokenImg: styled.img`
        position: absolute;
        width: 100%;
        top: 0;
        z-index: 25;
    `,
};

export default Styles;
