import { useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { BarContext } from "..";
import { AppContext } from "../../../App";
import { setupProgressBar, updateBonusSize } from "./helpers";
import {
   // IBonusType,
  //  IPlayerProgressObj,
    IPrizeType,
    ISetupObject,
} from "./types";

export const useProgressBarHook = (
    progressBarPrizesData: IPrizeType[][],
    setDisplayReward: any,
    setRewardType: any,
    setReward: any
) => {
    const location = useLocation();
    const history = useHistory();

    const {
        dispatchAppData,
        appData: { startPoints, playerDeltaPoints, playerLevel },
    } = useContext(AppContext);

    const {
        dispatchBarData,
        barData: { coinsLeftToGive },
    } = useContext(BarContext);

    let setupState: ISetupObject = {
        percents: 110,
        playerProgressArr: [],
        barNumber: 0,
        bars: [],
        bonuses: [],
        clouds: [],
        nextLevelPoints: 0,
    };

    const [setup, setSetup] = useState(setupState);
    // const [startingPoints, setStartingPoints] = useState(650); //test player point
    // const [deltaPoints, setDeltaPoints] = useState(60);
    const [startingPoints, setStartingPoints] = useState(startPoints);
    const [deltaPoints, setDeltaPoints] = useState(playerDeltaPoints);
    const [nextLevelTrigger, setNextLevelTrigger] = useState(false);
    const [hasNewLevel, setHasNewLevel] = useState(false);

    useEffect(() => {
        if (progressBarPrizesData && progressBarPrizesData.length > 0) {
            const {
                setup,
                coinsLeftToGive,
                nextLevelName,
                hasNewLevel,
                showPrevLevel,
            } = setupProgressBar(
                progressBarPrizesData,
                startingPoints,
                parseInt(deltaPoints.toString()),
                playerLevel
            );
            setSetup(setup);
            setHasNewLevel(hasNewLevel);
            dispatchBarData({
                startPktAmount: startingPoints,
                coinsLeftToGive: coinsLeftToGive,
                playerLevel: playerLevel + 1,
                nextLevelName: nextLevelName,
                nextLevelTrigger: showPrevLevel,
            });
        }
    }, [progressBarPrizesData, nextLevelTrigger]);

    // SECOND PART
    const { percents, playerProgressArr, bars, bonuses, clouds } = setup;

    const [anim, setAnim] = useState(percents);
    const [barAnimActive, setbarAnimActive] = useState(false);
    const [startAnim, setStartAnim] = useState(0);
    const [startFlashAnim, setStartFlashAnim] = useState(0);
    const [animActive, setAnimActive] = useState(false);
    const [bonusFlashActive, setBonusFlashActive] = useState(false);
    const [bonusFlashPosition, setBonusFlashPosition] = useState(0);

    useEffect(() => {
        const animTime = 5500;
        const fullTime = playerProgressArr.length * animTime;

        // Reset all triggers
        dispatchBarData({
            nextLevelIconFlashTrigger: false,
            headerTextAnimationActive: false,
            headerSecondTextAnimationActive: false,
            headerThirdTextAnimationActive: false,
        });

        if (percents < 110) {
            setTimeout(() => {
                setbarAnimActive(true);
            }, 100);
            // }
            setAnim(percents);
            if (!nextLevelTrigger) {
                setTimeout(() => {
                    setAnimActive(true);
                    setStartAnim(Math.random());
                    dispatchBarData({ headerTextAnimationActive: true });
                }, 1000);
                setTimeout(() => {
                    setAnimActive(false);
                }, 2300);
            }

            playerProgressArr.map((progress, idx) => {
                setTimeout(() => {
                    dispatchBarData({ headerTextAnimationActive: false });
                    dispatchBarData({ headerSecondTextAnimationActive: true });
                    setAnim((prev) => prev - progress.barPercents);
                    AnimateCounter(
                        progress.playerStartingPoints,
                        progress.playerGain
                    );
                }, 2500 + idx * animTime);

                if (hasNewLevel && progress.bonusType === "level") {
                    setTimeout(() => {
                        dispatchBarData({ nextLevelIconFlashTrigger: true });
                    }, 4200 + idx * animTime);
                    setTimeout(() => {
                        dispatchBarData({
                            headerThirdTextAnimationActive: false,
                        });
                        dispatchBarData({
                            headerNextLevelAnimationActive: true,
                        });
                    }, 4500 + idx * animTime);
                    setTimeout(() => {
                        dispatchBarData({ nextLevelAnimation: true });
                    }, 5000 + idx * animTime);
                    setTimeout(() => {
                        dispatchBarData({
                            nextLevelScreen: true,
                        });
                        setDeltaPoints(
                            startingPoints + deltaPoints - setup.nextLevelPoints
                        );
                        setStartingPoints(setup.nextLevelPoints);
                    }, 5400 + idx * animTime);
                    setTimeout(() => {
                        setNextLevelTrigger(true);
                    }, 6700 + idx * animTime);
                    setTimeout(() => {
                        dispatchAppData({ gifTrigger: true });
                    }, 9000 + idx * animTime);
                    setTimeout(() => {
                        dispatchBarData({
                            nextLevelScreen: false,
                            nextLevelAnimation: false,
                        });
                    }, 9200 + idx * animTime);
                }

                if (progress.bonusFlashPosition !== 0) {
                    setTimeout(() => {
                        setBonusFlashActive(true);
                        setStartFlashAnim(Math.random());
                        setBonusFlashPosition(
                            bonuses[progress.bonusIndex].left
                        );
                        // dispatchBarData({ coinRewardFlashActive: false });
                    }, 3900 + idx * animTime);
                    setTimeout(() => {
                        dispatchAppData({ gifTrigger: true });
                        setRewardType(progress.bonusType);
                        setReward(bonuses[progress.bonusIndex]);
                    }, 4800 + idx * animTime);
                    setTimeout(() => {
                        setDisplayReward(true);
                        updateBonusSize(bonuses, progress.bonusIndex);
                    }, 5000 + idx * animTime);
                    setTimeout(() => {
                        dispatchAppData({ gifTrigger: true });
                    }, 6500 + idx * animTime);
                    setTimeout(() => {
                        setDisplayReward(false);
                        dispatchBarData({
                            headerThirdTextAnimationActive: true,
                        });
                    }, 6700 + idx * animTime);
                    if (progress.bonusType === "token") {
                        setTimeout(() => {
                            dispatchBarData({ coinRewardFlashActive: true });
                            dispatchBarData({
                                coinRewardTrigger: Math.random(),
                            });
                            const coinsLeft = coinsLeftToGive;
                            dispatchBarData({
                                coinsLeftToGive: coinsLeft - 1,
                            });
                        }, 7000 + idx * animTime);
                    }
                }
            });
            // USTAWIC TOP NAV TRIGGERY NA FALSE PO ZAKONCZENIU PASKA
            if (
                !hasNewLevel ||
                playerProgressArr.filter((el) => el.bonusType === "level")
                    .length === 0
            ) {
                setTimeout(() => {
                    dispatchAppData({ gifTrigger: true });
                }, fullTime);
                setTimeout(() => {
                    dispatchAppData({ inMaze: false });
                    const path = location.pathname.slice(0, 6);
                    history.push(path);
                }, fullTime + 200);
            }
        }
    }, [percents]);

    //calculate and run counting animation of points
    const AnimateCounter = (startValue: number, pointsAmount: number) => {
        const overalDuration = 1000;
        const stepDuration = overalDuration / pointsAmount;
        for (let i = 0; i <= pointsAmount; i++) {
            setTimeout(() => {
                dispatchBarData({ startPktAmount: startValue + i });
            }, stepDuration * i);
        }
    };

    return {
        bars,
        bonuses,
        anim,
        barAnimActive,
        clouds,
        percents,
        animActive,
        startAnim,
        bonusFlashActive,
        bonusFlashPosition,
        startFlashAnim,
    };
};
