import styled from "styled-components";

const Styles = {
    CongratsContainer: styled.div`
        display: flex;
        justify-content: center;
        align-items: center;
        /* border: 2px solid black; */
        left: 2%;
    `,
    PointsText: styled.div`
        font-size: 15vw;
        color: white;
        text-shadow: 6px 6px 0px rgba(10, 10, 10, 1);
        transform: rotate(-3deg);
        /* border: 5px solid #ffffff; */
    `,
    ImagePosition: styled.div`
        z-index: 60;
        /* border: 2px solid black; */
        & > img {
            width: 60%;
        }
    `,
};

export default Styles;
