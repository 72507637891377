import IVideoPLayer from "./types"
import "./styles.css"

export default function VideoPlayer({ source, autoPlay, loop, muted, controls}: IVideoPLayer) {
  return (
      <video className="video video-player" controls={controls} autoPlay={autoPlay} loop={loop} muted={muted}>
        <source src={`${source}`} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
  );
}
