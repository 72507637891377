const appContext = {
    appData: {
        gifTrigger: false,
        mazeGifTrigger: false,
        xMassMazeGifTrigger: false,
        currentMazeSpot: 0,
        mazeSpotsCount: 0,
        currentXMassMazeSpot: 0,
        xMassMazeSpotsCount: 0,
        playerLevel: 0,
        startPoints: 0,
        deltaPoints: 0,
        playerDeltaPoints: 0,
        finishPoints: 0,
        timeLeftToPlay: 0,
        reward: {},
        logged: false,
        loginData: {},
        infoData: {},
        networkError: false,
        cardAtKioskResponding: false,
        successRoute: "",
        failureRoute: "",
        testPlayerAtKiosk: {},
        inMaze: false,
        RFIDSimulateTrigger: "",
        reactToRFID: true,
        progressBarPrizesData: {},
        levels: {},
        tokens: {},
        premiums: {},
        animatorAtKiosk: false,
        animatorCameScreen: false,
        missingGroupPoints: 0,
        animatorDeltaPointsTrigger: false,
        playerStats:{
            speed: 0,
            climbing: 0,
            jumping: 0,
            agality: 0,
            flair: 0
        }
    },
    dispatchAppData: null,
};

export default appContext;
