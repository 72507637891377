import ScreenBackground from "../../templates/ScreenBackground";
import DoubleNav from "../../templates/DoubleNav";
import useComeBackTimer from "./hook";
import Clock from "./Clock";
import ScreenFrame from "../../templates/ScreenFrame";

const ComeBackTimer = () => {
    const { timeLeft } = useComeBackTimer();

    return (
        <ScreenFrame>
            <ScreenBackground />
            <DoubleNav />
            <Clock timeLeft={timeLeft} />
        </ScreenFrame>
    );
};

export default ComeBackTimer;
