//import React from "react";
import ScreenFrame from "../../../templates/ScreenFrame";
//import ScreenBackground from "../../../templates/ScreenBackground";
import VideoPlayer from "../../../templates/VideoPlayer";
import IHeroPresent from "./types";
import { heroVideos } from "../data";

export default function HeroPresent ({hero}:IHeroPresent){
    return ( 
        <ScreenFrame isVertical>
            <VideoPlayer source={heroVideos[hero]} autoPlay loop={false} muted controls={false}/>
        </ScreenFrame>
    )
}