import mazeAssets from "../../../data/assets/images/maze";
const {
    stampOn_1,
    stampOn_2,
    stampOn_3,
    stampOn_4,
    stampOn_5,
    stampOn_6,
    stampOn_7,
    stampOn_8,
    stampOn_9,
    stampOn_10,
    stampOn_11,
    stampOn_12,
    stampOff_1,
    stampOff_2,
    stampOff_3,
    stampOff_4,
    stampOff_5,
    stampOff_6,
    stampOff_7,
    stampOff_8,
    stampOff_9,
    stampOff_10,
    stampOff_11,
    stampOff_12,
    stampAnim_1,
    stampAnim_2,
    stampAnim_3,
    stampAnim_4,
    stampAnim_5,
    stampAnim_6,
    stampAnim_7,
    stampAnim_8,
    stampAnim_9,
    stampAnim_10,
    stampAnim_11,
    stampAnim_12,
} = mazeAssets;

const completedStamps:{[key:string]:string} = {
    "1": stampOn_1,
    "2": stampOn_2,
    "3": stampOn_3,
    "4": stampOn_4,
    "5": stampOn_5,
    "6": stampOn_6,
    "7": stampOn_7,
    "8": stampOn_8,
    "9": stampOn_9,
    "10": stampOn_10,
    "11": stampOn_11,
    "12": stampOn_12,
};

const uncompletedStamps:{[key:string]:string} = {
    "1": stampOff_1,
    "2": stampOff_2,
    "3": stampOff_3,
    "4": stampOff_4,
    "5": stampOff_5,
    "6": stampOff_6,
    "7": stampOff_7,
    "8": stampOff_8,
    "9": stampOff_9,
    "10": stampOff_10,
    "11": stampOff_11,
    "12": stampOff_12,
};

const currentStamps:{[key:string]:string} = {
    "1": stampAnim_1,
    "2": stampAnim_2,
    "3": stampAnim_3,
    "4": stampAnim_4,
    "5": stampAnim_5,
    "6": stampAnim_6,
    "7": stampAnim_7,
    "8": stampAnim_8,
    "9": stampAnim_9,
    "10": stampAnim_10,
    "11": stampAnim_11,
    "12": stampAnim_12,
};

export { completedStamps, uncompletedStamps, currentStamps };
