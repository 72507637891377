import styled, { css } from "styled-components";
import { IAnimatedHeader } from "./types";
import {
    longHeaderAnimation,
    timerInfoAnimation,
    headerAnimation,
} from "./keyframes";

const Styles = {
    Placeholder: styled.div`
        top: -12%;
        position: absolute;
        width: 100%;
        height: 35%;
        z-index: 30;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
    `,
    Background: styled.img`
        position: absolute;
        height: auto;
        width: 125%;
        left: -16%;
    `,
    PointsIcon: styled.img`
        margin-left: 2%;
        width: 8%;
    `,
    AnimatedHeader: styled.h1<IAnimatedHeader>`
        ${({ animationType }) =>
            animationType === "super-short" &&
            css`
                animation: ${headerAnimation} 1.6s ease-out;
            `}
        ${({ animationType }) =>
            animationType === "short" &&
            css`
                animation: ${headerAnimation} 2s ease-out;
            `}
        ${({ animationType }) =>
            animationType === "long" &&
            css`
                animation: ${longHeaderAnimation} 2s ease-out;
            `}
        ${({ animationType }) =>
            animationType === "timer" &&
            css`
                animation: ${timerInfoAnimation} 4.2s ease-out;
            `}
        ${({ animationType }) =>
            animationType === "delay" &&
            css`
                animation: ${headerAnimation} 2s ease-out;
                animation-delay: 2s;
            `}
        animation-fill-mode: forwards;
        bottom: 30%;
        color: white;
        position: absolute;
        font-size: 4vw;
        text-align: center;
        margin: 0;
        text-shadow: 4px 4px 3px rgba(115, 0, 153, 1);
        opacity: 0;
    `,
};

export default Styles;
