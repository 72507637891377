//import React from "react";
import Styles from "./styles";
import useErrorVertical from "./hook";
import { useLocation } from "react-router";
//import ImagesData from "../../../../data/assets/images/images";
import ScreenBackground from "../../../templates/ScreenBackground";
import VideoPlayer from "../../../templates/VideoPlayer";
import awariaBand from "../../../../data/assets/videos/awaria-band.mp4"
import awariaKiosk from "../../../../data/assets/videos/awaria-kiosk.mp4"

const {
    ScreenWrapper,
    Content,
  //  Shadow,
  //  Star,
  //  ScreenNameWrapper,
  //  ImageWithSize,
  //  ImageWithPos,
} = Styles;

//const {
  //  brokenKiosk,
 //   brokenBand,
 //   defeatAnotherChallenge,
 //  exchangeBand,
  //  shadow,
  //  logoCMK,
  //  starfish,
//} = ImagesData;

const ErrorVertical = () => {
    const { pathname } = useLocation();
    useErrorVertical(pathname);
    return (
        <Content>
            <ScreenWrapper>
                <ScreenBackground />
                {pathname.includes("/band") && (
                     <VideoPlayer source={awariaBand} autoPlay loop={true} muted controls={false}/>
                )}
                {pathname.includes("/kiosk") && (
                     <VideoPlayer source={awariaKiosk} autoPlay loop={true} muted controls={false}/>
                )}
            </ScreenWrapper>
        </Content>
    );
};

export default ErrorVertical;
