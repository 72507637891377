import React from "react";
import ImagesData from "../../../../data/assets/images/images";
import Styles from "./styles";

const { ImagePosition, PointsText, CongratsContainer } = Styles;

const { bigDiamond } = ImagesData;

interface Props {
    allPoints: number;
}

const CongratsScreen = (props: Props) => {
    return (
        <React.Fragment>
            <CongratsContainer>
                <PointsText>+{props.allPoints}</PointsText>
                <ImagePosition>
                    <img src={bigDiamond} alt="Big Diamond" />
                </ImagePosition>
            </CongratsContainer>
        </React.Fragment>
    );
};

export default CongratsScreen;
