import styled from "styled-components";

const Styles = {
    Placeholder: styled.div`
        bottom: -10%;
        position: absolute;
        width: 100%;
        height: 35%;
        z-index: 30;
        display: flex;
        justify-content: center;
        align-items: center;
    `,
    Background: styled.img`
        left: -1%;
        top: 0;
        position: absolute;
        height: 100%;
        width: 125%;
    `,
};

export default Styles;
