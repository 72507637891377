import { IBonusType, IPrizeType, IRetType } from "./types";

export const calculateFlashLeft = (percents: number) => {
    if (percents > 93) return -33;
    if (percents < 7) return 53;
    return 60 - percents;
};

export const calculatePointsLeft = (percents: number) => {
    if (percents > 93) return -3;
    if (percents < 7) return 83;
    return 90 - percents;
};

const getPrizes = (
    progressBarPrizesData: IPrizeType[][],
    playerPoints: number,
    playerLevel: number
): IRetType => {
    let prizes: IPrizeType[] = [];
    let level = 0;
    let nextLevelName = "";

    let nextLevelPoints = 0;
    for (let i = 0; i < progressBarPrizesData.length; i++) {
        prizes = progressBarPrizesData[i];
        level = i + 1;
        if (
            playerPoints >= prizes[0].pointsReq &&
            playerPoints < prizes[prizes.length - 1].pointsReq
        ) {
            nextLevelPoints = prizes[prizes.length - 1].pointsReq;
            nextLevelName = prizes[prizes.length - 1].levelName!;
            return {
                prizes,
                level,
                nextLevelPoints,
                nextLevelName,
            };
        }
    }

    // Player has the highest level possible
    if (progressBarPrizesData.length > 0) {
        prizes = [
            progressBarPrizesData[progressBarPrizesData.length - 1].slice(
                -1
            )[0],
        ];
    } else {
        prizes = [
            {
                type: "level",
                pointsReq: 0,
                levelName: "ADEPT",
            },
        ];
    }

    nextLevelPoints = 100000;
    nextLevelName = "";
    prizes.push({
        type: "level",
        pointsReq: nextLevelPoints,
        levelName: nextLevelName,
    });
    level = progressBarPrizesData.length + 1;

    return { prizes, level, nextLevelPoints, nextLevelName };
};

export const setupProgressBar = (
    progressBarPrizesData: IPrizeType[][],
    playerPoints: number,
    gainedPoints: number,
    playerLevel: number
) => {
    let percents = 0;
    let barNumber = 0;
    let diff = 0;
    let threshhold = 0;
    let bars = [];
    let bonuses = [];
    let clouds = [];

    const { prizes, level, nextLevelPoints, nextLevelName } = getPrizes(
        progressBarPrizesData,
        playerPoints,
        playerLevel
    );

    const hasNewLevel = playerLevel === level;
    const showPrevLevel = playerLevel >= level;

    const count = prizes.length - 1;
    const gap = 100 / count;

    let prevPoints = prizes[0].pointsReq; //starting points for a level
    for (let i = 0; i < count; i++) {
        bars.push({ width: gap - 0.25, left: i * gap });
        const points = prizes[i + 1].pointsReq;
        if (playerPoints >= prevPoints && playerPoints < points) {
            barNumber = i;
            diff = points - prevPoints;
            threshhold = prevPoints;
        }
        prevPoints = points;
    }

    for (let i = 1; i < count; i++) {
        bonuses.push({
            left: i - 1 === barNumber ? i * gap - 4 : i * gap - 2.25,
            width: i - 1 === barNumber ? 8 : 4.5,
            type: prizes[i].type,
            formula: prizes[i].formula,
            noofsec: prizes[i].noofsec,
            whenapplied: prizes[i].whenapplied,
        });
        clouds.push({ left: i * gap - 3, text: prizes[i].pointsReq });
    }

    percents = barNumber * gap;
    const overPoints = (((playerPoints - threshhold) / diff) * 100) / count;
    percents += overPoints;
    percents = 100 - percents;

    percents = Math.max(percents, 10);

    let playerProgressArr = [];
    const allPoints = playerPoints + gainedPoints;
    if (allPoints >= prizes[barNumber + 1].pointsReq) {
        let lastPrize = barNumber + 1;
        if (!hasNewLevel && prizes[lastPrize].type === "level") {
            playerProgressArr.push({
                barPercents: percents <= 10 ? 5 : gap - overPoints - 5,
                playerStartingPoints: playerPoints,
                playerGain: prizes[lastPrize].pointsReq - playerPoints,
                bonusType: prizes[lastPrize].type,
                bonusFlashPosition: 0,
                bonusIndex: -1,
            });
        } else {
            playerProgressArr.push({
                barPercents: percents <= 10 ? 10 : gap - overPoints,
                playerStartingPoints: playerPoints,
                playerGain: prizes[lastPrize].pointsReq - playerPoints,
                bonusType: prizes[lastPrize].type,
                bonusFlashPosition:
                    prizes[lastPrize].type !== "level"
                        ? bonuses[lastPrize - 1].left
                        : 0,
                bonusIndex:
                    prizes[lastPrize].type !== "level" ? lastPrize - 1 : -1,
            });
        }

        for (
            let i = barNumber + 2;
            i < prizes.length && allPoints >= prizes[i].pointsReq;
            i++
        ) {
            if (!hasNewLevel && prizes[i].type === "level") {
                playerProgressArr.push({
                    barPercents: gap - 5,
                    playerStartingPoints: prizes[i - 1].pointsReq,
                    playerGain: prizes[i].pointsReq - playerPoints,
                    bonusType: prizes[i].type,
                    bonusFlashPosition: 0,
                    bonusIndex: -1,
                });
            } else {
                playerProgressArr.push({
                    barPercents: gap,
                    playerStartingPoints: prizes[i - 1].pointsReq,
                    playerGain: prizes[i].pointsReq - prizes[i - 1].pointsReq,
                    bonusType: prizes[i].type,
                    bonusFlashPosition:
                        prizes[i].type !== "level" ? bonuses[i - 1].left : 0,
                    bonusIndex: prizes[i].type !== "level" ? i - 1 : -1,
                });
            }
           
            lastPrize = i;
        }
        if (allPoints < prizes[count].pointsReq) {
            const remainingPoints = allPoints - prizes[lastPrize].pointsReq;
            const remDiff =
                prizes[lastPrize + 1].pointsReq - prizes[lastPrize].pointsReq;
            let remPerc = ((remainingPoints / remDiff) * 100) / count;
            if (lastPrize + 1 === count) {
                remPerc = Math.min(gap - 5, remPerc);
            }
            playerProgressArr.push({
                barPercents: remPerc,
                playerStartingPoints: prizes[lastPrize].pointsReq,
                playerGain: remainingPoints,
                bonusType: "",
                bonusFlashPosition: 0,
                bonusIndex: -1,
            });
        }

    } else {
        playerProgressArr.push({
            barPercents: Math.min(95, ((gainedPoints / diff) * 100) / count),
            playerStartingPoints: playerPoints,
            playerGain: gainedPoints,
            bonusType: "",
            bonusFlashPosition: 0,
            bonusIndex: -1,
        });
    }

    const coinsLeftToGive = playerProgressArr.reduce((prev, curr) => {
        return prev + (curr.bonusType === "token" ? 1 : 0);
    }, 0);

    const setup = {
        percents,
        playerProgressArr,
        barNumber,
        bars,
        bonuses,
        clouds,
        nextLevelPoints,
    };
    return {
        setup,
        coinsLeftToGive,
        nextLevelName,
        hasNewLevel,
        showPrevLevel,
    };
};

export const updateBonusSize = (bonuses: IBonusType[], index: number) => {
    bonuses[index].left += 1.75;
    bonuses[index].width = 4.5;
    if (index + 1 < bonuses.length) {
        bonuses[index + 1].left -= 1.75;
        bonuses[index + 1].width = 8;
    }
};

