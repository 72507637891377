import React from "react";
import ImagesData from "../../../../../../data/assets/images/images";
import useLevelsInfo from "./hook";
import "./styles.css"

const { arrowRight } = ImagesData;

const LevelsInfo = () => {
    const { level,levelIcon,currentLevelName,nextLevelIcon,nextLevelName,levels,playerLevel} = useLevelsInfo()

    return (
        <div className='levels-info__level-info-container '>
            <div className='levels-info__left-level'>
                <div className='levels-info__level-icon levels-info__level-symbol-icon'>
                    <img src={levelIcon} alt="left lvl icon" />
                </div>
                <div className='levels-info__col'>
                <div className='levels-info__level-number'>LEVEL {level}</div>
                    {currentLevelName.includes(" ") ? (
                        <>
                            <div className="levels-info__level-name" style={{fontSize:'3.5vw'}}>
                                {currentLevelName.split(" ")[0]}
                            </div>
                            <div className="levels-info__level-name" style={{fontSize:'3.5vw'}}>
                                {currentLevelName.split(" ")[1]}
                            </div>
                        </>
                    ) : (
                        <div className="levels-info__level-name" style={{fontSize:'4.5vw'}}>{currentLevelName}</div>
                    )}
                </div>
            </div>
            <div className='levels-info__left-level'>
                {playerLevel < levels.length && (
                    <React.Fragment>
                         <div className='levels-info__col'>
                            <div className='levels-info__level-number'>LEVEL {level + 1}</div>
                            {nextLevelName.includes(" ") ? (
                                <>
                                    <div className="levels-info__level-name" style={{fontSize:'3.5vw'}}>
                                        {nextLevelName.split(" ")[0]}
                                    </div>
                                    <div className="levels-info__level-name" style={{fontSize:'3.5vw'}}>
                                        {nextLevelName.split(" ")[1]}
                                    </div>
                                </>
                            ) : (
                                <div className="levels-info__level-name" style={{fontSize:'4.5vw'}}>{nextLevelName}</div>
                            )} 
                        </div>
                        <div className='levels-info__level-icon levels-info__level-symbol-icon'>
                            <img src={nextLevelIcon} alt="left lvl icon" />
           
                        </div>
                        <div className='levels-info__level-icon'>
                            <img src={arrowRight} alt="left lvl icon" />
                        </div>
                    </React.Fragment>
                )}
            </div>
        </div>
    );
};

export default LevelsInfo;
