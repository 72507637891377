const pointsActiveLookupTable: {[key:string]:boolean} = {
    "1": true,
    "2": true,
    "3": true,
    "4": true, ///labirynt
    "5": true,
    "6": true,
    "7": true,
    "8": true,
    "10": true,
    "12": true,
    "13": false,
    "14": false,
    "15": false,
    "16": false,
    "17": true,
    "19": true,
    "20": true,
    "21": true,
    "22": true,
    "23": true,
    "24": true,
};

export { pointsActiveLookupTable };
