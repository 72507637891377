import xMassMazeAssets from "../../../data/assets/images/xMassMaze";
const {
    tree_1_unlocked,
    tree_2_unlocked,
    tree_3_unlocked,
    tree_4_unlocked,
    tree_1,
    tree_2,
    tree_3,
    tree_4,
    tree_1_anim,
    tree_2_anim,
    tree_3_anim,
    tree_4_anim,
} = xMassMazeAssets;

const completedStamps:{[key:string]:string} = {
    "1": tree_1_unlocked,
    "2": tree_2_unlocked,
    "3": tree_3_unlocked,
    "4": tree_4_unlocked,
};

const uncompletedStamps:{[key:string]:string} = {
    "1": tree_1,
    "2": tree_2,
    "3": tree_3,
    "4": tree_4,
};

const currentStamps:{[key:string]:string} = {
    "1": tree_1_anim,
    "2": tree_2_anim,
    "3": tree_3_anim,
    "4": tree_4_anim,

};

export { completedStamps, uncompletedStamps, currentStamps };
