import Styles from "./styles";
import FlashElementsData from "./data";
import { IScreenFlash } from "./types";

const { AnimationContainer, AnimatedImage } = Styles;

const ScreenFlash = ({ active }: IScreenFlash) => {
    return (
        <AnimationContainer>
            {active === true &&
                FlashElementsData.map((i) => (
                    <AnimatedImage key={i.id} delay={i.delay} src={i.image} />
                ))}
        </AnimationContainer>
    );
};

export default ScreenFlash;
