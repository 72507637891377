import styled from "styled-components";

const Styles = {
    TitleWrapper: styled.div`
        top: 3%;
        position: absolute;
        width: 100%;
        height: 15%;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 20;
    `,
    ScreenTitle: styled.img`
        width: 40%;
    `,
    ScreenNameWrapper: styled.div`
        bottom: 5%;
        position: absolute;
        width: 100%;
        height: 15%;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 20;
    `,
    ScreenName: styled.img`
        width: 25%;
    `,
};

export default Styles;
