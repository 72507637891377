import avatar from "./AVATAR.png";
import cloud from "./CHMURKA.png";
import shadow from "./CIEN.png";
import counter_test from "./counter.png";
import clock from "./CZAS_IKONA.png";
import diamond from "./DIAMENT_IKONA.png";
import bottomNavBackground from "./DOLNY_PASEK.png";
import logoCMK from "./GOKIDZ_LOGO_CMYK.png";
import topNavBackground from "./GORNY_PASEK.png";
import stars1 from "./GWIAZDY_1.png";
import stars2 from "./GWIAZDY_2.png";
import cave from "./LOGO_ATRAKCJA.png";
import kioskIcon from "./KIOSK_IKONA.png";
import kioskIconGreen from "./KIOSK_IKONA_GREEN.png";
import coin from "./ZETON_DUZY.png";
import loadingBar from "./PASEK_LADOWANIA.png";
import progressBar from "./PROGRESS_BAR.png";
import scanBand from "./PRZYLOZ_OPASKE.png";
import gradientRectangle from "./Rectangle.png";
import xMassRectangle from "./xMassRectangle.png";
import frame from "./RAMKA.png";
import handIcon from "./REKA_IKONA.png";
import starfish from "./ROZGWIAZDA.png";
import chest from "./SKRZYNIA.png";
import arrowRight from "./STRZALKA_PRAWO.png";
import heroes from "./postacie.png";
import nameBackground from "./pasek_ksywka.png";
import superText from "./SUPER_.png";
import haveGoodPlayText from "./BAWSIEDOBRZE.png";
import getTokenText from "./ODBIERZ_ZETON.png";

import battle from "./bitwa.png";
import cosmicDisco from "./cosmicdisco.png";
import cosmicZoo from "./cosmiczoo.png";
import demolish from "./demolka.png";
import maze from "./labirynt.png";
import miniPlanet from "./miniplaneta.png";
import octopus from "./osmiornica.png";
import jumps from "./skoki.png";
import sports from "./sporty.png";
import tornado from "./tornado.png";
import volcano from "./wulkan.png";
import race from "./wyscig.png";
import rally from "./zjazd.png";
import ninja from "./ninja_logo.png";
import xmass from "./x-mass-title.png"

import brokenKiosk from "./AWARIA_KIOSKU.png";
import brokenBand from "./AWARIA_OPASKI.png";
import defeatAnotherChallenge from "./POKONAJ_INNE_WYZWANIE.png";
import scanBandAgain from "./PRZYLOZ_PONOWNIE_OPASKE.png";
import exchangeBand from "./WYMIEN_OPASKE.png";

import gateOpenText from "./BRAMKA_OTWARTA.png";
import gateEnterText from "./ZETON_WEJSCIE.png";

import screenFlash from "../gif/EKRAN_PRZEJSCIE.gif";
import coinFlash from "../gif/MONETA_BLYSK.gif";
import pointsFlash from "../gif/NABICIE_PUNKTOW.gif";
import progressBarFlash from "../gif/BLYSK_POD_IKONY.gif";
import mazeFlashLeftTop from "../gif/MazeFlashes/left-top.gif";
import mazeFlashLeftBottom from "../gif/MazeFlashes/left-bottom.gif";
import mazeFlashRightTop from "../gif/MazeFlashes/right-top.gif";
import mazeFlashRightBottom from "../gif/MazeFlashes/right-bottom.gif";
import optimizedMazeFlash from "../gif/MazeFlashes/optimizedMazeFlash.gif";
import tokenReady from "../gif/zdobycie_zetonu_udane.gif";
import tokenFail from "../gif/zdobycie_zetonu_nieudane.gif";
import gateIdle from "../gif/BITWA_1.gif";
import gateOpen from "../gif/BITWA_2.gif";
import nextLevelAnim from "../gif/zdobycie_poziomu.gif";

import AnimatorAtKioskText from "./ANIMATOR_PRZY_KIOSKU.png";
import AnimatorAtKiosk from "./ANIMATOR_JEST.png";
import AnimatorAwayText from "./ANIMATOR_ODSZEDL.png";
import AnimatorAway from "./ANIMATOR_X.png";

import birthdayMissingPointsText from "./birthday/BRAKUJE_WAM.png";
import bigDiamond from "./birthday/DIAMENT_DUZY.png";
import birthdayCongratsText from "./birthday/GRATULACJE_DRUZYNO.png";
import birthdayMissionLogo from "./birthday/MISJA_URO_LOGO.png";
import nexusHeroIcon from "./birthday/NEXUS.png";
import animatorTokenIcon from "./birthday/ZETON_ANIMATOR.png";
import animatorTokenText from "./birthday/ZETONY_U_ANIMATORA.png";

import rankingDailyText from "./ranking/RANKING_DNIA.png";

import totalPlayTimeTextTop from "./ranking/GRASZ_JUZ.png";
import totalPlayTimeTextBottom from "./ranking/MINUT.png";

import avatarChimeron from "./avatars/chimeron.png";
import avatarExpresso from "./avatars/expresso.png";
import avatarFerven from "./avatars/ferven.png";
import avatarFlex from "./avatars/flex.png";
import avatarNexus from "./avatars/nexus.png";
import avatarScande from "./avatars/scande.png";
import avatarUmbra from "./avatars/umbra.png";

import renifer from "./avatars/RENIFER.png";

import levelIcon1 from "./levelIcons/1.png";
import levelIcon2 from "./levelIcons/2.png";
import levelIcon3 from "./levelIcons/3.png";
import levelIcon4 from "./levelIcons/4.png";
import levelIcon5 from "./levelIcons/5.png";
import levelIcon6 from "./levelIcons/6.png";
import levelIcon7 from "./levelIcons/7.png";
import levelIcon9 from "./levelIcons/WIEDZA.png";
import levelIcon8 from "./levelIcons/ENERGIA.png";
import levelIcon10 from "./levelIcons/BOHATER.png";
import levelIcon11 from "./levelIcons/MOC.png";
import levelIcon12 from "./levelIcons/ODWAGA.png";
import levelIcon13 from "./levelIcons/SILA.png";
import levelIcon14 from "./levelIcons/SPRYT.png";
import levelIcon15 from "./levelIcons/ARENA.png";
import levelIcon16 from "./levelIcons/PRZYMIERZE.png";
import levelIcon17 from "./levelIcons/SKOCZNOSC.png";
import levelIcon18 from "./levelIcons/ZWINNOSC.png";
import levelIcon19 from "./levelIcons/SZYBKOSC.png";
import levelIcon20 from "./levelIcons/TECHNOLOGIA.png";

import smallLevelIcon1 from "./levelIconsSmall/1.png";
import smallLevelIcon2 from "./levelIconsSmall/2.png";
import smallLevelIcon3 from "./levelIconsSmall/3.png";
import smallLevelIcon4 from "./levelIconsSmall/4.png";
import smallLevelIcon5 from "./levelIconsSmall/5.png";
import smallLevelIcon6 from "./levelIconsSmall/6.png";
import smallLevelIcon7 from "./levelIconsSmall/7.png";
import smallLevelIcon9 from "./levelIconsSmall/WIEDZA.png";
import smallLevelIcon8 from "./levelIconsSmall/ENERGIA.png";
import smallLevelIcon10 from "./levelIconsSmall/BOHATER.png";
import smallLevelIcon11 from "./levelIconsSmall/MOC.png";
import smallLevelIcon12 from "./levelIconsSmall/ODWAGA.png";
import smallLevelIcon13 from "./levelIconsSmall/SILA.png";
import smallLevelIcon14 from "./levelIconsSmall/SPRYT.png";
import smallLevelIcon15 from "./levelIconsSmall/ARENA.png";
import smallLevelIcon16 from "./levelIconsSmall/PRZYMIERZE.png";
import smallLevelIcon17 from "./levelIconsSmall/SKOCZNOSC.png";
import smallLevelIcon18 from "./levelIconsSmall/ZWINNOSC.png";
import smallLevelIcon19 from "./levelIconsSmall/SZYBKOSC.png";
import smallLevelIcon20 from "./levelIconsSmall/TECHNOLOGIA.png";

import levelUp from "./levelNames/LEVEL_UP.png";

const levelIcons = [
    levelIcon1,
    levelIcon2,
    levelIcon3,
    levelIcon4,
    levelIcon5,
    levelIcon6,
    levelIcon7,
    levelIcon8,
    levelIcon9,
    levelIcon10,
    levelIcon11,
    levelIcon12,
    levelIcon13,
    levelIcon14,
    levelIcon15,
    levelIcon16,
    levelIcon17,
    levelIcon18,
    levelIcon19,
    levelIcon20,
];

const smallLevelIcons = [
    smallLevelIcon1,
    smallLevelIcon2,
    smallLevelIcon3,
    smallLevelIcon4,
    smallLevelIcon5,
    smallLevelIcon6,
    smallLevelIcon7,
    smallLevelIcon8,
    smallLevelIcon9,
    smallLevelIcon10,
    smallLevelIcon11,
    smallLevelIcon12,
    smallLevelIcon13,
    smallLevelIcon14,
    smallLevelIcon15,
    smallLevelIcon16,
    smallLevelIcon17,
    smallLevelIcon18,
    smallLevelIcon19,
    smallLevelIcon20,
];

const GetLevelIcon = (level: number, small: boolean = false) => {
    if (!small) {
        if (level - 1 >= levelIcons.length)
            return levelIcons[levelIcons.length - 1];
        return levelIcons[level - 1];
    } else {
        if (level - 1 >= smallLevelIcons.length)
            return smallLevelIcons[smallLevelIcons.length - 1];
        return smallLevelIcons[level - 1];
    }
};

const GetNextLevelIcon = (level: number, small: boolean = false) => {
    if (!small) {
        if (level >= levelIcons.length - 1) {
            return levelIcons[levelIcons.length - 1];
        }
        return levelIcons[level];
    } else {
        if (level >= smallLevelIcons.length - 1) {
            return smallLevelIcons[smallLevelIcons.length - 1];
        }
        return smallLevelIcons[level];
    }
};

const ImagesData = {
    avatar,
    cloud,
    shadow,
    counter_test,
    clock,
    diamond,
    bottomNavBackground,
    logoCMK,
    topNavBackground,
    stars1,
    stars2,
    cave,
    kioskIcon,
    kioskIconGreen,
    coin,
    loadingBar,
    progressBar,
    scanBand,
    gradientRectangle,
    xMassRectangle,
    frame,
    handIcon,
    starfish,
    chest,
    arrowRight,
    screenFlash,
    coinFlash,
    pointsFlash,
    progressBarFlash,
    mazeFlashLeftTop,
    mazeFlashLeftBottom,
    mazeFlashRightTop,
    mazeFlashRightBottom,
    optimizedMazeFlash,
    heroes,
    nameBackground,
    superText,
    haveGoodPlayText,
    getTokenText,
    tokenReady,
    tokenFail,

    battle,
    cosmicDisco,
    cosmicZoo,
    maze,
    miniPlanet,
    demolish,
    octopus,
    jumps,
    sports,
    tornado,
    volcano,
    race,
    rally,
    ninja,
    xmass,

    gateIdle,
    gateOpen,

    brokenKiosk,
    brokenBand,
    defeatAnotherChallenge,
    scanBandAgain,
    exchangeBand,

    gateOpenText,
    gateEnterText,

    AnimatorAtKioskText,
    AnimatorAtKiosk,
    AnimatorAwayText,
    AnimatorAway,

    birthdayMissingPointsText,
    bigDiamond,
    birthdayCongratsText,
    birthdayMissionLogo,
    nexusHeroIcon,
    animatorTokenIcon,
    animatorTokenText,

    rankingDailyText,
    totalPlayTimeTextTop,
    totalPlayTimeTextBottom,

    avatarChimeron,
    avatarExpresso,
    avatarFerven,
    avatarFlex,
    avatarNexus,
    avatarScande,
    avatarUmbra,

    renifer,

    nextLevelAnim,
    levelUp,

    GetLevelIcon,
    GetNextLevelIcon,
};

export default ImagesData;
