import styled from "styled-components";

const StrokeText = styled.div`
    color: white;
    font-size: 18vw;
    letter-spacing: 1px;
`;

const Styles = {
    Placeholder: styled.div`
        top: 11%;
        position: absolute;
        width: 100%;
        height: 35%;
        z-index: 30;
        display: flex;
        justify-content: center;
        align-items: center;
    `,
    Time: styled(StrokeText)`
        z-index: 40;
        -webkit-text-stroke-width: 1vw;
        -webkit-text-stroke-color: #702283;
        -webkit-text-fill-color: #ffffff;
    `,
    TimeSecondStroke: styled(StrokeText)`
        position: absolute;
        z-index: 35;
        -webkit-text-stroke-width: 2vw;
        -webkit-text-stroke-color: #d20bab;
        -webkit-text-fill-color: #ffffff;
    `,
    ClockText: styled.div`
        position: absolute;
        z-index: 40;
        color: white;
        font-size: 3vw;
        letter-spacing: 0.3vw;
    `,
    ClockWrapper: styled.div`
        position: absolute;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
    `,
    ClockImage: styled.img`
        position: relative;
        width: 13vw;
        height: 25.6vh;
        z-index: 40;
        margin-right: 2vw;
    `,
    Shadow: styled.img`
        width: 40%;
        height: 20%;
        position: absolute;
        left: 40%;
        bottom: -80%;
        z-index: 15;
    `,
};

export default Styles;
