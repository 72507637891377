import styled, { keyframes } from "styled-components";
import { IFlashImage, IFlashPosition, IFlashOptimized } from "./types";

const imgFlash = keyframes`
    0%{
        visibility: hidden;
    }
    25%{
        visibility: visible;
    }
    100%{
        visibility: hidden;
    }
`;

const Styles = {
    AnimationContainer: styled.div`
        overflow: hidden;
        position: absolute;
        width: 100%;
        height: 100%;
        /* width: 3840px;
        height: 2160px; */
        z-index: 1100;
        display: flex;
        /* flex-wrap: wrap; */
        flex-direction: row;
        justify-content: center;
        align-items: center;
    `,
    ContentContainer: styled.div`
        width: 100vw;
        height: 100vh;
    `,
    AnimationImage: styled.img<IFlashPosition>`
        position: relative;
        right: ${({ right }) => right};
        bottom: ${({ bottom }) => bottom};
        width: 100%;
        height: 100%;
        animation: 1.8s ease in out;
        overflow: hidden;
    `,
    AnimationImageOptimized: styled.img<IFlashOptimized>`
        position: relative;
        left: ${({ left }) => left};
        top: ${({ top }) => top};
        width: 180%;
        height: 180%;
        animation: 1.8s ease in out;
        /* overflow: hidden; */
        transform: ${({ transform }) => transform};
    `,
    AnimationPosition: styled.div<IFlashPosition>`
        position: relative;
        right: ${({ right }) => right};
        bottom: ${({ bottom }) => bottom};
    `,
    AnimatedImage: styled.img<IFlashImage>`
        overflow: hidden;
        position: absolute;
        z-index: 1100;
        width: 100%;
        height: 100%;
        right: ${({ right }) => right};
        bottom: ${({ bottom }) => bottom};
        visibility: hidden;
        animation: ${imgFlash} 0.04s;
        animation-delay: ${({ delay }) => delay};
        transform: ${({ transform }) => transform};
    `,
};

export default Styles;
