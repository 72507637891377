import styled, { keyframes } from "styled-components";
import { ILevelWrapper } from "./types";

const newLevelKeyframes = keyframes`
    0%{
        opacity: 0;
    }
    50%{
        opacity: 0;
    }
    100%{
        opacity: 1;
    }
`;
const animTime = 3;

export const Styles = {
    Wrapper: styled.div<ILevelWrapper>`
        position: absolute;
        width: 100%;
        height: 100%;
        display: ${({ displayReward }) =>
            displayReward === true ? "flex" : "none"};
        z-index: 90;
        justify-content: center;
        align-items: center;
    `,
    BackgroundWrapper: styled.div`
        animation: ${newLevelKeyframes} ${animTime}s ease-in-out;
    `,
    Frame: styled.img`
        animation: ${newLevelKeyframes} ${animTime}s ease-in-out;
        position: absolute;
        z-index: 20;
        width: 90%;
        height: 80%;
    `,
    Star: styled.img`
        animation: ${newLevelKeyframes} ${animTime}s ease-in-out;
        position: absolute;
        z-index: 25;
        width: 40%;
        height: 58%;
    `,
    Shadow: styled.img`
        animation: ${newLevelKeyframes} ${animTime}s ease-in-out;
        width: 90%;
        height: auto;
        position: absolute;
        left: 5%;
        bottom: 0;
        z-index: 15;
    `,
};
