import { keyframes } from "styled-components";

const imgFlash = keyframes`
    0%{
        visibility: visible;
    }
    100%{
        visibility: hidden;
    }
`;

export default imgFlash;
