import React from "react";
import ImagesData from "../../../../../data/assets/images/images";
import Styles from "./styles";

const {
    ProgressBarContainer,
    BarsContainer,
    Bar,
    BonusContainer,
    BonusPrize,
    BarBgImage,
} = Styles;

const { loadingBar, coin, chest, progressBar } = ImagesData;

interface Props {
    bars: { width: number; left: number }[];
    bonuses: { width: number; left: number; type: string }[];
    anim: number;
    barAnimActive: boolean;
}

const BarWithBonuses = (props: Props) => {
    const bars = props.bars;
    const bonuses = props.bonuses;
    return (
        <ProgressBarContainer>
            <BarsContainer trans={props.anim} active={props.barAnimActive}>
                {bars.map((bar, idx) => {
                    return (
                        <Bar
                            key={idx}
                            width={bar.width}
                            left={bar.left}
                            src={loadingBar}
                        />
                    );
                })}
            </BarsContainer>
            <BonusContainer>
                {bonuses.map((bonus, idx) => {
                    // return (
                    //     <BonusPrize
                    //         left={bonus.left}
                    //         src={bonus.type === "token" ? coin : chest}
                    //         width={bonus.width}
                    //     />
                    // );
                    return bonus.type === "token" ? (
                        <BonusPrize
                            key={idx}
                            left={bonus.left}
                            src={coin}
                            width={bonus.width}
                        />
                    ) : (
                        <BonusPrize
                            key={idx}
                            left={bonus.left - 0.5}
                            src={chest}
                            width={bonus.width + 1}
                        />
                    );
                })}
            </BonusContainer>
            <BarBgImage>
                <img src={progressBar} alt="bar-bg-img" />
            </BarBgImage>
        </ProgressBarContainer>
    );
};

export default BarWithBonuses;
