import React, { useContext, useState } from "react";
import ScreenBackground from "../../templates/ScreenBackground";
import Styles from "./styles";
import DoubleNav from "../../templates/DoubleNav";
import Images from "../../../data/assets/images/images";
import { AppContext } from "../../App";
import ScreenFrame from "../../templates/ScreenFrame";
import useTokenBox from "./hook";

const { TokenImg } = Styles;
const { tokenFail, tokenReady } = Images;

const TokenBox = () => {
    const [animTrigger] = useState("?x=" + Math.random());
    const {
        appData: {
            testPlayerAtKiosk: {
                data: {
                    data: { PlayerTokens },
                },
            },
        },
    } = useContext(AppContext);
    useTokenBox();

    return (
        <ScreenFrame>
            <ScreenBackground />
            <DoubleNav />
            <TokenImg
                src={
                    parseInt(PlayerTokens) > 0
                        ? tokenReady + animTrigger
                        : tokenFail + animTrigger
                }
            />
        </ScreenFrame>
    );
};

export default TokenBox;
