import styled from "styled-components";
import { IBonusPosition } from "./types";

export const Styles = {
    RowCloudContainer: styled.div`
        display: flex;
    `,
    CloudContainer: styled.div<IBonusPosition>`
        position: absolute;
        left: ${(props) => props.left}%;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 6%;
        /* border: 2px solid black; */
        /* z-index: 60; */
    `,
    CloudPoints: styled.img`
        width: 100%;
        z-index: 60;
    `,
    CloudText: styled.div`
        position: absolute;
        top: 25%;
        color: white;
        font-size: 1.6vw;
        text-shadow: 3px 3px 3px rgba(42, 0, 42, 1);
        z-index: 60;
    `,
};

export default Styles;
