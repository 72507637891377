import styled from "styled-components";
import { IImageSize, IImagePos } from "./types";

const Styles = {
    ScreenWrapper: styled.div`
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100vw;
        height: 100vh;
        max-width: calc(100vh * 0.576);
        max-height: calc(100vw * 1.74);
    `,
    Content: styled.div`
        width: 100vw;
        height: 100vh;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
    `,
    Shadow: styled.img`
        width: 50%;
        height: 3%;
        position: absolute;
        left: 25%;
        bottom: 2%;
        z-index: 15;
    `,
    Star: styled.img`
        position: absolute;
        width: 150%;
        z-index: 20;
    `,

    TitleWrapper: styled.div`
        background-color: #ff00be;
        top: 2%;
        position: absolute;
        width: 75%;
        height: 5%;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 25;
        box-shadow: 5px 5px 0px rgba(10, 10, 10, 1);
    `,
    PlayerName: styled.div`
        font-size: 3vh;
        color: white;
        text-shadow: 3px 3px 0px rgba(10, 10, 10, 1);
        z-index: 30;
    `,
    ScreenNameWrapper: styled.div`
        bottom: 0%;
        position: absolute;
        width: 100%;
        height: 15%;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 20;
    `,
    ImageWithPos: styled.img<IImagePos>`
        position: absolute;
        top: ${(props) => props.top}%;
        left: ${(props) => (props.left ? props.left + "%" : "auto")};
        width: ${(props) => props.size}%;
        z-index: 30;
    `,
    ImageWithSize: styled.img<IImageSize>`
        width: ${(props) => props.size}%;
        z-index: 30;
    `,
    PointsText: styled.div`
        position: absolute;
        font-size: 13vh;
        color: white;
        text-shadow: 7px 7px 0px rgba(10, 10, 10, 1);
        left: 40%;
        z-index: 30;
    `,
};

export default Styles;
