import ImagesData from "../../../../../data/assets/images/images";

const {
    avatar,
    avatarChimeron,
    avatarExpresso,
    avatarFerven,
    avatarFlex,
    avatarNexus,
    avatarScande,
    avatarUmbra,
    renifer
} = ImagesData;

export const avatarTable:{[key:string]:{
    avatar: string
    top: number
    right: number
    width: number
}} = {Renifer: {
    avatar: renifer, // done
    top: -80,
    right: 0,
    width: 55,
},
    CHIMERON: {
        avatar: avatarChimeron, // done
        top: -60,
        right: 0,
        width: 95,
    },
    EXPRESSO: {
        avatar: avatarExpresso, // done
        top: -73,
        right: 2,
        width: 90,
    },
    FERVEN: {
        avatar: avatarFerven, // done
        top: -75,
        right: 1,
        width: 88,
    },
    FLEX: {
        avatar: avatarFlex, // done
        top: -60,
        right: 1,
        width: 65,
    },
    NEXUS: {
        avatar: avatarNexus, //done
        top: -65,
        right: 1,
        width: 55,
    },
    SCANDE: {
        avatar: avatarScande, //done
        top: -60,
        right: 3,
        width: 60,
    },
    UMBRA: {
        avatar: avatarUmbra, //done
        top: -64,
        right: 1,
        width: 55,
    },
    "": {
        avatar: avatar, //done
        top: -70,
        right: 5,
        width: 80,
    },
};
