import Styles from "./styled";
import IRewardScreen from "./types";
import BonusReward from "./BonusReward";
import CoinReward from "./CoinReward";
import ImagesData from "../../../../data/assets/images/images";
import ScreenBackground from "../../../templates/ScreenBackground";

const { Frame, Shadow, Wrapper, Star } = Styles;
const { frame, shadow, starfish } = ImagesData;

const RewardScreen = ({ displayReward, rewardType, reward }: IRewardScreen) => {
    return (
        <Wrapper displayReward={displayReward}>
            <ScreenBackground />
            <Frame src={frame} />
            <Shadow src={shadow} />
            <Star src={starfish} />

            {rewardType === "token" && <CoinReward display={displayReward} />}
            {rewardType === "chest" && <BonusReward reward={reward} />}
        </Wrapper>
    );
};

export default RewardScreen;
