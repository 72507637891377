//import React from "react";
import { IPrizeType } from "./types";

export const prepBarData = (levels: any, tokens: any, premiums: any) => {
    let barData: IPrizeType[][] = [];
    if (levels.data && tokens.data && premiums.data) {
        const levelObjs = levels.data.data;
        for (let i = 0; i < levelObjs.length - 1; i++) {
            let prizes = [];
            const levelPointsMin = parseInt(levelObjs[i].pointsrequired);
            const levelPointsMax = parseInt(levelObjs[i + 1].pointsrequired);
            for (let token of tokens.data.data) {
                const tokenPoints = parseInt(token.pointsrequired);
                if (
                    tokenPoints > levelPointsMin &&
                    tokenPoints < levelPointsMax
                ) {
                    prizes.push({
                        type: "token",
                        pointsReq: tokenPoints,
                    });
                }
            }
            for (let chest of premiums.data.data) {
                const chestPoints = parseInt(chest.piontsRequired);
                if (
                    chestPoints > levelPointsMin &&
                    chestPoints < levelPointsMax
                ) {
                    prizes.push({
                        type: "chest",
                        pointsReq: chestPoints,
                        formula: chest.formula,
                        noofsec: chest.noofsec,
                        whenapplied: chest.whenapplied,
                    });
                }
            }
            prizes.push(
                {
                    type: "level",
                    pointsReq: levelPointsMin,
                    levelName: levelObjs[i].name,
                },
                {
                    type: "level",
                    pointsReq: levelPointsMax,
                    levelName: levelObjs[i + 1].name,
                }
            );
            prizes.sort((a, b) => {
                return a.pointsReq - b.pointsReq;
            });
           
            barData.push(prizes);
        }
    }
    return barData;
};

