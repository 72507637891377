import React from "react";
import ImagesData from "../../../../data/assets/images/images";
import Styles from "./styled";
import ScreenBackground from "../../../templates/ScreenBackground";
import { useLocation } from "react-router";
import ScreenFrame from "../../../templates/ScreenFrame";
import useError from "./hook";

const {
    Frame,
    Shadow,
    TitleWrapper,
    ScreenTitle,
    ScreenName,
    ScreenNameWrapper,
    Star,
    KioskWrapper,
} = Styles;

const {
    brokenKiosk,
    brokenBand,
    defeatAnotherChallenge,
    exchangeBand,
    frame,
    shadow,
    logoCMK,
    starfish,
} = ImagesData;

const ErrorHorizontal = () => {
    const { pathname } = useLocation();
    useError(pathname);
    return (
        <ScreenFrame>
            <ScreenBackground />
            <Frame src={frame} />
            <Shadow src={shadow} />
            <Star src={starfish} />
            <KioskWrapper>
                {pathname.includes("/band-error") && (
                    <ScreenTitle src={brokenBand} />
                )}
                {pathname.includes("/kiosk-error") && (
                    <ScreenTitle src={brokenKiosk} />
                )}
            </KioskWrapper>
            <TitleWrapper>
                {pathname.includes("/band-error") && (
                    <ScreenTitle src={exchangeBand} />
                )}
                {pathname.includes("/kiosk-error") && (
                    <ScreenTitle src={defeatAnotherChallenge} />
                )}
            </TitleWrapper>
            <ScreenNameWrapper>
                <ScreenName src={logoCMK} />
            </ScreenNameWrapper>
        </ScreenFrame>
    );
};

export default ErrorHorizontal;
