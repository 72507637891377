//import React from "react";
import { Switch, Route, useHistory } from "react-router-dom";
import componentsData from "../../../data/components";
import GKK from "../../GKK";
import Styles from "./styles";
//import Screen00 from "../../Screens/_DOMAIN/Screen44";
import Screen04 from "../../Screens/_DOMAIN/Screen04";
import Screen05 from "../../Screens/_DOMAIN/Screen05";
import Screen06 from "../../Screens/_DOMAIN/Screen06";
import Screen08 from "../../Screens/_DOMAIN/Screen08";
import Screen14 from "../../Screens/_DOMAIN/Screen14";
import Screen15 from "../../Screens/_DOMAIN/Screen15";
import Screen20 from "../../Screens/_DOMAIN/Screen20";
import Screen21 from "../../Screens/_DOMAIN/Screen21";
import Screen22 from "../../Screens/_DOMAIN/Screen22";
import Screen23 from "../../Screens/_DOMAIN/Screen23";
import Screen25 from "../../Screens/_DOMAIN/Screen25";
import Screen26 from "../../Screens/_DOMAIN/Screen26";
import Screen27 from "../../Screens/_DOMAIN/Screen27";
import Screen30 from "../../Screens/_DOMAIN/Screen30";
import Screen32 from "../../Screens/_DOMAIN/Screen32";
import Screen33 from "../../Screens/_DOMAIN/Screen33";
import Screen36 from "../../Screens/_DOMAIN/Screen36";
import Screen40 from "../../Screens/_DOMAIN/Screen40";
import Screen46 from "../../Screens/_DOMAIN/Screen46";
import TokenPayer from "../../Screens/TokenBox/TokenPayer";
import useRoutes from "./hook";

const { Container, RoutesWrapper, RouteLink } = Styles;

const Routes = () => {
    const history = useHistory();
    const { startScreen } = useRoutes();
    const goToScreen = (slug: string) => {
        setTimeout(() => {
            history.push(slug);
        }, 200);
    };

    return (
        <Switch>
            <Route exact path="/">
                <Container>
                    <RoutesWrapper>
                        {componentsData.map((g) => (
                            <RouteLink
                                key={g.id}
                                onClick={() => {
                                    goToScreen("/" + g.id);
                                }}
                            >
                                {g.id}
                            </RouteLink>
                        ))}
                    </RoutesWrapper>
                </Container>
            </Route>
            {/* RANKING HERE */}
            <Route exact path="/ranking" key="/ranking" component={Screen27} />
            <Route
                key={"ranking-total-playtime"}
                path={"/ranking/total-playtime"}
                component={Screen32}
            ></Route>
            <Route
                key={"park-enter-screen"}
                path={"/ranking/total-playtime"}
                component={Screen32}
            ></Route>
            {/* END OF RANKING HERE */}
            {componentsData.map((g) => (
                <Route exact path={"/" + g.id} key={"/" + g.id}>
                    <GKK id={g.id} data={startScreen} />
                </Route>
            ))}
            {componentsData.map((g) => (
                <Route
                    key={g.id + "-scan-band"}
                    path={"/" + g.id + "/scan-band"}
                    component={Screen06}
                ></Route>
            ))}
            {componentsData.map((g) => (
                <Route
                    key={g.id + "-progress-bar"}
                    path={"/" + g.id + "/progress-bar-test"}
                    component={Screen08}
                ></Route>
            ))}
            {componentsData.map((g) => (
                <Route
                    key={g.id + "-timmer"}
                    path={"/" + g.id + "/come-back-counter"}
                    component={Screen04}
                ></Route>
            ))}
            {componentsData.map((g) => (
                <Route
                    key={g.id + "-band-error"}
                    path={"/" + g.id + "/band-error"}
                    component={Screen15}
                ></Route>
            ))}
            {componentsData.map((g) => (
                <Route
                    key={g.id + "-kiosk-error"}
                    path={"/" + g.id + "/kiosk-error"}
                    component={Screen15}
                ></Route>
            ))}
            {componentsData.map((g) => (
                <Route
                    key={g.id + "-welcome"}
                    path={"/" + g.id + "/welcome"}
                    component={Screen14}
                ></Route>
            ))}
            {componentsData.map((g) => (
                <Route
                    key={g.id + "-token-box"}
                    path={"/" + g.id + "/token-box"}
                    component={Screen05}
                ></Route>
            ))}
            {componentsData.map((g) => (
                <Route
                    key={g.id + "-gate-enter"}
                    path={"/" + g.id + "/gate-enter"}
                    component={Screen26}
                ></Route>
            ))}
            {componentsData.map((g) => (
                <Route
                    key={g.id + "-gate"}
                    path={"/" + g.id + "/gate"}
                    component={Screen30}
                ></Route>
            ))}
            {componentsData.map((g) => (
                <Route
                    key={g.id + "-token-payer"}
                    path={"/" + g.id + "/token-payer"}
                    component={TokenPayer}
                ></Route>
            ))}
            {componentsData.map((g) => (
                <Route
                    key={g.id + "-maze"}
                    path={"/" + g.id + "/maze"}
                    component={Screen25}
                ></Route>
            ))}
            {componentsData.map((g) => (
                <Route
                    key={g.id + "-x-mass-maze"}
                    path={"/" + g.id + "/x-mass-maze"}
                    component={Screen46}
                ></Route>
            ))}
            {componentsData.map((g) => (
                <Route
                    key={g.id + "-animator-at-kiosk"}
                    path={"/" + g.id + "/animator-at-kiosk"}
                    component={Screen20}
                ></Route>
            ))}
            {componentsData.map((g) => (
                <Route
                    key={g.id + "-animator-away"}
                    path={"/" + g.id + "/animator-away"}
                    component={Screen21}
                ></Route>
            ))}
            {componentsData.map((g) => (
                <Route
                    key={g.id + "-birthday-points-missing"}
                    path={"/" + g.id + "/birthday-points-missing"}
                    component={Screen23}
                ></Route>
            ))}
            {componentsData.map((g) => (
                <Route
                    key={g.id + "-birthday-get-tokens"}
                    path={"/" + g.id + "/birthday-get-tokens"}
                    component={Screen33}
                ></Route>
            ))}
            {componentsData.map((g) => (
                <Route
                    key={g.id + "-birthday-congrats"}
                    path={"/" + g.id + "/birthday-congrats"}
                    component={Screen22}
                ></Route>
            ))}
            {componentsData.map((g) => (
                <Route
                    key={g.id + "-total-playtime"}
                    path={"/" + g.id + "/total-playtime"}
                    component={Screen32}
                ></Route>
            ))}
            {componentsData.map((g) => (
                <Route
                    key={g.id + "-band-error-vertical"}
                    path={"/" + g.id + "/band-error-vertical"}
                    component={Screen40}
                ></Route>
            ))}
            {componentsData.map((g) => (
                <Route
                    key={g.id + "-kiosk-error-vertical"}
                    path={"/" + g.id + "/kiosk-error-vertical"}
                    component={Screen40}
                ></Route>
            ))}
            {componentsData.map((g) => (
                <Route
                    key={g.id + "-load-hero"}
                    path={"/" + g.id + "/load-hero"}
                    component={Screen36}
                ></Route>
            ))}
        </Switch>
    );
};

export default Routes;
