import styled from "styled-components";

const Styles = {
    Container: styled.div`
        display: flex;
        width: 100%;
        height: 100vh;
        justify-content: center;
        align-items: center;
    `,

    RoutesWrapper: styled.div`
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        width: 400px;
    `,

    RouteLink: styled.div`
        font-family: Arial, Helvetica, sans-serif;
        font-weight: 600;
        color: black;
        padding: 10px;
        margin: 3px;
        z-index: 2300;
        transition: 0.3s;
        background-color: violet;
        &:hover {
            background-color: lightpink;
            cursor: pointer;
        }
    `,
};

export default Styles;
