import styled from "styled-components";

const Styles = {
    Frame: styled.img`
        position: absolute;
        z-index: 20;
        width: 90%;
        height: 80%;
    `,
    Shadow: styled.img`
        width: 90%;
        height: auto;
        position: absolute;
        left: 5%;
        bottom: 0;
        z-index: 15;
    `,
};

export default Styles;
