import styled from "styled-components";

const Styles = {
    GateImg: styled.img`
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        z-index: 60;
    `,
    TitleWrapper: styled.div`
        top: 3%;
        position: absolute;
        width: 100%;
        height: 15%;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 20;
    `,
    ScreenTitle: styled.img`
        width: 40%;
    `,
    Frame: styled.img`
        position: absolute;
        z-index: 20;
        width: 90%;
        height: 80%;
    `,
    Shadow: styled.img`
        width: 90%;
        height: auto;
        position: absolute;
        left: 5%;
        bottom: 0;
        z-index: 15;
    `,
    ScreenNameWrapper: styled.div`
        bottom: 5%;
        position: absolute;
        width: 100%;
        height: 15%;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 20;
    `,
    ScreenName: styled.img`
        width: 25%;
    `,
    Star: styled.img`
        position: absolute;
        width: 35%;
        z-index: 20;
    `,
};

export default Styles;
