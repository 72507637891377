import styled, { createGlobalStyle } from "styled-components";
import Marvin from "../../data/assets/fonts/Marvin.otf";
import Futura from "../../data/assets/fonts/FuturaTOT-ExtrBold.otf";
import { ConnectingAnim } from "./keyframes";

export const GlobalStyle = createGlobalStyle`

    @font-face {
        font-family: 'Futura';
        src: url(${Futura});
    }
    @font-face {
        font-family: 'Marvin';
        src: url(${Marvin});
    }

  body, html, div {
    font-family: Marvin;
    position: relative;
    margin: 0px;
    padding: 0px;
  }
`;

export const Column = styled.div`
    display: flex;
    flex-direction: column;
`;

export const ConnectingLabel = styled.div`
    color: white;
    position: absolute;
    z-index: 2000;
    bottom: 50px;
    right: 25px;
    letter-spacing: 0.3vw;
    font-size: 1.8vw;
    animation: ${ConnectingAnim} 2s ease-in-out infinite;
`;

export const LoginBackgroundContainer = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 1;
`;
