import styled from "styled-components";

const Styles = {
    Frame: styled.img`
        position: absolute;
        z-index: 20;
        width: 90%;
        height: 80%;
    `,
    Shadow: styled.img`
        width: 90%;
        height: auto;
        position: absolute;
        left: 5%;
        bottom: 0;
        z-index: 15;
    `,
    TitleWrapper: styled.div`
        top: 0%;
        position: absolute;
        width: 100%;
        height: 21%;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 20;
    `,
    ScreenTitle: styled.img`
        width: 53%;
    `,
    ScreenNameWrapper: styled.div`
        bottom: 5%;
        position: absolute;
        width: 100%;
        height: 15%;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 20;
    `,
    ScreenName: styled.img`
        width: 25%;
    `,
    AnimatorIconWrapper: styled.div`
        position: absolute;
        display: flex;
        justify-content: center;
        width: 50%;
        z-index: 25;
        right: 22%;
    `,
    Star: styled.img`
        position: absolute;
        width: 35%;
        z-index: 20;
    `,
};

export default Styles;
