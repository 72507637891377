import { keyframes } from "styled-components";

const tokenAnimation = keyframes`
    0%{
        transform: translateX(0%);
    }
    75%{
        transform: translateX(0%);
    }
    100%{
        transform: translateX(300%);
    }
`;

export { tokenAnimation };
