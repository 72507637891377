import { useEffect, useContext } from "react";
import { useHistory, useLocation } from "react-router";
import { AppContext } from "../../App";

const useTotalPlaytime = () => {
    const history = useHistory();
    const { pathname } = useLocation();

    // Safety mechanism
    const {
        appData: { testPlayerAtKiosk },
    } = useContext(AppContext);
    if (typeof testPlayerAtKiosk.data === "undefined") {
        history.push(pathname.replace("/total-playtime", ""));
        window.location.reload();
    }

    const {
        dispatchAppData,
        appData: {
            testPlayerAtKiosk: {
                data: {
                    data: { TotalPlaytimeMinutes, PlayerNickName },
                },
            },
        },
    } = useContext(AppContext);

    useEffect(() => {
        setTimeout(() => {
            dispatchAppData({ gifTrigger: true });
        }, 3800);
        setTimeout(() => {
            history.push(pathname.replace("/total-playtime", ""));
        }, 4000);
    }, []);

    return { TotalPlaytimeMinutes, PlayerNickName };
};

export default useTotalPlaytime;
