import React from "react";
import ScreenFrame from "../../templates/ScreenFrame";
import ScreenBackground from "../../templates/ScreenBackground";
import useGate from "./hook";
import GateOpen from "./Open";
import GateClosed from "./Closed";

const Gate = () => {
    const PlayerTokens = useGate();
    return (
        <ScreenFrame>
            <ScreenBackground />
            {PlayerTokens > 0 ? <GateOpen /> : <GateClosed />}
        </ScreenFrame>
    );
};

export default Gate;
