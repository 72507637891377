import React from "react";
import { useLocation } from "react-router";
import ScreenFrame from "../../templates/ScreenFrame";
import ScreenBackground from "../../templates/ScreenBackground";
import ImagesData from "../../../data/assets/images/images";
import Styles from "./styles";
import useBirthday from "./hook";
import MissingPoints from "./components/missingPoints";
import CongratsScreen from "./components/congratsScreen";

const {
    Frame,
    Shadow,
    TitleWrapper,
    ScreenNameWrapper,
    Star,
    CenterIconWrapper,
    ImageWithSize,
} = Styles;

const {
    frame,
    shadow,
    starfish,
    birthdayMissingPointsText,
    birthdayCongratsText,
    birthdayMissionLogo,
    animatorTokenIcon,
    animatorTokenText,
} = ImagesData;

const BirthdayScreen = () => {
    const { pathname } = useLocation();
    const { missingGroupPoints, allGroupPoints } = useBirthday(pathname);
    return (
        <ScreenFrame>
            <ScreenBackground />
            <Frame src={frame} />
            <Shadow src={shadow} />
            <Star src={starfish} />
            <TitleWrapper>
                {pathname.includes("/birthday-points-missing") && (
                    <ImageWithSize src={birthdayMissingPointsText} size={40} />
                )}
                {pathname.includes("/birthday-get-tokens") && (
                    <ImageWithSize src={animatorTokenText} size={53} />
                )}
                {pathname.includes("/birthday-congrats") && (
                    <ImageWithSize src={birthdayCongratsText} size={53} />
                )}
            </TitleWrapper>
            <CenterIconWrapper>
                {pathname.includes("/birthday-points-missing") && (
                    <MissingPoints missingPoints={missingGroupPoints} />
                )}
                {pathname.includes("/birthday-get-tokens") && (
                    <ImageWithSize src={animatorTokenIcon} size={50} />
                )}
                {pathname.includes("/birthday-congrats") && (
                    <CongratsScreen allPoints={allGroupPoints} />
                )}
            </CenterIconWrapper>
            <ScreenNameWrapper>
                <ImageWithSize src={birthdayMissionLogo} size={42} />
            </ScreenNameWrapper>
        </ScreenFrame>
    );
};

export default BirthdayScreen;
