import React from "react";
import ImagesData from "../../../../../data/assets/images/images";
import Styles from "./styles";

const { RowCloudContainer, CloudContainer, CloudPoints, CloudText } = Styles;
const { cloud } = ImagesData;

interface Props {
    clouds: { left: number; text: number }[];
}

const CloudRow = (props: Props) => {
    const clouds = props.clouds;
    return (
        <RowCloudContainer>
            {clouds.map((cloudElement, idx) => {
                return (
                    <CloudContainer key={idx} left={cloudElement.left}>
                        <CloudPoints src={cloud} />
                        <CloudText>{cloudElement.text}</CloudText>
                    </CloudContainer>
                );
            })}
        </RowCloudContainer>
    );
};

export default CloudRow;
