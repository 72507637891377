import styled from "styled-components";
import { IRewardWrapper } from "./types";

const Styles = {
    Wrapper: styled.div<IRewardWrapper>`
        position: absolute;
        width: 100%;
        height: 100%;
        display: ${({ displayReward }) =>
            displayReward === true ? "flex" : "none"};
        z-index: 90;
        justify-content: center;
        align-items: center;
    `,
    Frame: styled.img`
        position: absolute;
        z-index: 20;
        width: 90%;
        height: 80%;
    `,
    Star: styled.img`
        position: absolute;
        z-index: 25;
        width: 40%;
        height: 58%;
    `,
    Shadow: styled.img`
        width: 90%;
        height: auto;
        position: absolute;
        left: 5%;
        bottom: 0;
        z-index: 15;
    `,
};
export default Styles;
