import { useEffect, useState } from "react";
import ImagesData from "../../../../../data/assets/images/images";
import Styles from "./styled";

const { Coin, CoinAnim } = Styles;
const { coin, coinFlash } = ImagesData;
//const coinSrc = coinFlash + "?x=" + Math.random();

const CoinReward = (props: { display: boolean }) => {
    const [random, setRandom] = useState(Math.random());
    useEffect(() => {
        if (props.display) {
            setRandom(Math.random());
        }
    }, [props.display]);
    return (
        <>
            <Coin src={coin} />
            <CoinAnim src={coinFlash + "?x=" + random} />
        </>
    );
};

export default CoinReward;
