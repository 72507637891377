import React, { PropsWithChildren } from "react";
import Styles from "./styles";
import IScreenFrame from "./types";

const { ScreenWrapper, Content } = Styles;

const ScreenFrame = ({ children, isVertical = false }: PropsWithChildren<IScreenFrame>) => {
    return (
        <Content >
            <ScreenWrapper isVertical={isVertical}>{children}</ScreenWrapper>
        </Content>
    );
};

export default ScreenFrame;
