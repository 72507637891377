import { keyframes } from "styled-components";

const ConnectingAnim = keyframes`
    0%{
        transform:translateY(0px)
    }
    50%{
        transform: translateY(-15px)
    }
    100%{
        transform: translateY(0px);
    }
`;

export { ConnectingAnim };
