import Styles from "./styled";
import PlayerStats from "./PlayerStats";
import Images from "../../../../data/assets/images/images";

const { Placeholder, Background } = Styles;
const { bottomNavBackground } = Images;

const BottomNav = () => {
    return (
        <Placeholder>
            <Background src={bottomNavBackground} />
            <PlayerStats />
        </Placeholder>
    );
};

export default BottomNav;
