import { keyframes } from "styled-components";

const headerAnimation = keyframes`
    0%{
        opacity:1;
        transform: translateY(-150%);
    }
    22%{
        transform: translateY(10%);
    }
    90%{
        transform: translateY(0%);
        opacity: 1;
    }
    100%{
        transform: translateY(0%);
        opacity: 0;
    }

`;
const timerInfoAnimation = keyframes`
    0%{
        opacity: 1;
        transform: translateY(-150%);
    }
    10%{
        transform: translateY(10%);
    }
    95%{
        transform: translateY(0%);
        opacity: 1;
    }
    100%{
        transform: translateY(0%);
        opacity: 0;
    }

`;

const longHeaderAnimation = keyframes`
    0%{
        opacity: 1;
        transform: translateY(-150%);
    }
    22%{
        transform: translateY(10%);
    }
    90%{
        transform: translateY(0%);
    }
    100%{
        opacity: 1;
        transform: translateY(0%);
    }

`;

export { longHeaderAnimation, timerInfoAnimation, headerAnimation };
