import React from "react";
import ImagesData from "../../../../../../data/assets/images/images";
import progressBarBgBegin from "../../../../../../data/assets/images/parkEnteranceDashboard/pasek_poczatek_poziom.png"
import progressBarBg from "../../../../../../data/assets/images/parkEnteranceDashboard/pasek_srodek_poziom.png"
import progressBarBgEnd from "../../../../../../data/assets/images/parkEnteranceDashboard/pasek_poczatek_poziom kopia.png"
import progressBarFill from "../../../../../../data/assets/images/parkEnteranceDashboard/pasek_progress_poziom.png"
import { IBarWithBonuses } from "./types";
import "./styles.css"

const { coin, chest, cloud } = ImagesData;

const BarWithBonuses = ({currentPoints,progressBarsToFillAmount,singleProgressBarFillWidth,levelBonusses,amountToFillProgressBar,progressBarOverflow}: IBarWithBonuses) => {
    return (
        <div className='bar-with-bonuses__container' style={{
            transform: progressBarOverflow === true ? `translateX(-75%)` : `translateX(0%)`}}>
                <div className='bar-with-bonuses__bg-container'>
                    <img className='bar-with-bonuses__bar-bg-edge' alt="background element" src={progressBarBgBegin}/>
                    <img className='bar-with-bonuses__bar-bg' alt="background element" src={progressBarBg}/>
                    <img className='bar-with-bonuses__bar-bg' alt="background element" src={progressBarBg}/>
                    <img className='bar-with-bonuses__bar-bg' alt="background element" src={progressBarBg}/>
                    <img className='bar-with-bonuses__bar-bg' alt="background element" src={progressBarBg}/>
                    <img className='bar-with-bonuses__bar-bg-edge' alt="background element" src={progressBarBgEnd}/>
                    <div className='bar-with-bonuses__bg-fill-container'>    
                        {
                            <div className='bar-with-bonuses__bg-fill-container-mask' style={{clipPath: `polygon(0 0, ${amountToFillProgressBar}% 0, ${amountToFillProgressBar}% 100%, 0% 100%)`}}>
                                {
                                    Array(progressBarsToFillAmount).fill(<img key={Math.random()} className='bar-with-bonuses__bar'  alt="background element" src={progressBarFill} style={{width:`${singleProgressBarFillWidth}%`}}/>)
                                }
                            </div>   
                            }
                        {
                            levelBonusses.map((bonus, idx) => {
                                return bonus.type === "coin" ? (
                                    <span key={idx} className={`bar-with-bonuses__bonus ${currentPoints > parseInt(bonus.pointsrequired) ? 'is-earned' : ''}`}>
                                        <img alt="background element"
                                            className='bar-with-bonuses__coin-prize'
                                            style={{left:`${((idx + 1) * singleProgressBarFillWidth) + (idx * 0.7) - 2}%`}}
                                            key={idx}
                                            src={coin}
                                        />
                                        <div className='bar-with-bonuses__cloud' style={{left:`${((idx + 1) * singleProgressBarFillWidth) + (idx * 0.7) - 1.9}%`}}>
                                            <img className='bar-with-bonuses__cloud-points' alt="background element" src={cloud} />
                                            <div className='bar-with-bonuses__cloud-text'>{bonus.pointsrequired}</div>
                                        </div>
                                    </span>
                                  
                                ) : (
                                    <span key={idx} className={`bar-with-bonuses__bonus ${currentPoints > parseInt(bonus.pointsrequired) ? 'is-earned' : ''}`}>
                                        <img alt="background element"
                                            className='bar-with-bonuses__chest-prize'
                                            key={idx}
                                            style={{left:`${((idx + 1) * singleProgressBarFillWidth) + (idx * 0.7) - 2.5}%`}}
                                            src={chest}
                                        />
                                        <div className='bar-with-bonuses__cloud' style={{left:`${((idx + 1) * singleProgressBarFillWidth) + (idx * 0.7) - 1.9}%`}}>
                                            <img className='bar-with-bonuses__cloud-points' alt="background element" src={cloud} />
                                            <div className='bar-with-bonuses__cloud-text'>{bonus.pointsrequired}</div>
                                        </div>
                                    </span>
                                );
                            })}
                    </div>
                </div>
        </div>
    );
};

export default BarWithBonuses;
