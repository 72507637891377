import styled from "styled-components";
import { tokenAnimation } from "./keyframes";

const Styles = {
    Star: styled.img`
        position: absolute;
        z-index: 25;
        width: 40%;
        height: 58%;
    `,
    ScreenWrapper: styled.div`
        overflow: hidden;
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100vw;
        height: 100vh;
        max-width: calc(100vh * 1.74);
        max-height: calc(100vw * 0.576);
    `,
    Content: styled.div`
        width: 100vw;
        height: 100vh;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
    `,
    Frame: styled.img`
        position: absolute;
        z-index: 20;
        width: 90%;
        height: 80%;
    `,
    Shadow: styled.img`
        width: 90%;
        height: auto;
        position: absolute;
        left: 5%;
        bottom: 0;
        z-index: 15;
    `,
    TokenImg: styled.img`
        animation: ${tokenAnimation} 2s ease-in;
        animation-fill-mode: forwards;
        position: absolute;
        width: 25%;
        top: 28%;
        z-index: 60;
    `,
    TokenFlash: styled.img`
        position: absolute;
        width: 40%;
        left: 31%;
        top: 14%;
        z-index: 65;
    `,
};

export default Styles;
