import {useState} from "react";
import { useContext } from "react";
import { AppContext } from "../../../../../App";
import ImagesData from "../../../../../../data/assets/images/images";
import { GetLevelName, GetNextLevelName } from "../../../../../../data/levelNames";
import { IUseLevelsInfoReturn } from "./types";

const { GetLevelIcon, GetNextLevelIcon } = ImagesData;

export default function useLevelsInfo():IUseLevelsInfoReturn {
    const {
        appData: { levels:{data:{data:levels}}, startPoints, playerDeltaPoints},
    } = useContext(AppContext);

    let currentPoints = startPoints + playerDeltaPoints;

    if(currentPoints > levels[levels.length - 1].pointsrequired){
        currentPoints = levels[levels.length - 1].pointsrequired - 10
    }

    const playerLevel = levels.findIndex((el:{pointsrequired:string}) => parseInt(el.pointsrequired) > currentPoints)

    const [level] = useState(playerLevel);

    const levelIcon = GetLevelIcon(level, true);
    const currentLevelName = GetLevelName(level);
    const nextLevelIcon = GetNextLevelIcon(level, true);
    const nextLevelName = GetNextLevelName(level);

    return {level,levelIcon,currentLevelName,nextLevelIcon,nextLevelName,levels, playerLevel}
}
