import styled from "styled-components";
import { bandAnimation } from "./keyframes";

const Styles = {
    AnimationWrapper: styled.div`
        animation: ${bandAnimation} infinite 1s ease-in-out;
        position: absolute;
        height: 45%;
        width: 30%;
        z-index: 30;
    `,
    Band: styled.img`
        position: absolute;
        bottom: 0;
        right: 5%;
        z-index: 30;
        width: 40%;
    `,
    Diamond: styled.img`
        position: absolute;
        height: 10%;
        top: 30%;
        left: 63%;
        z-index: 30;
    `,
    PointsText: styled.div`
        position: absolute;
        right: 37.5%;
        top: 28.5%;
        font-size: 7vw;
        color: white;
        text-shadow: -6px 6px 0px rgba(10, 10, 10, 1);
        z-index: 50;
    `,
};

export default Styles;
