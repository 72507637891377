import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../App";
import { completedStamps, uncompletedStamps, currentStamps } from "./data";
import { useLocation, useHistory } from "react-router";

const GenerateStaps = (
    noOfKiosksCompleted: number,
    totalKiosksForAttraction: number
) => {
    let generatedStamps = [];
    for (let i = 1; i <= totalKiosksForAttraction; i++) {
        if (i < noOfKiosksCompleted) {
            generatedStamps.push(completedStamps[i]);
        } else if (i == noOfKiosksCompleted) {   
            generatedStamps.push(currentStamps[i]);
        } else {
            generatedStamps.push(uncompletedStamps[i]);
        }
    }
    return generatedStamps;
};

const useMaze = () => {
    const [animTrigger, setAnimTrigger] = useState("?x=" + Math.random());
    const [allPointsCollected, setAllPointsCollected] = useState(false);
    const [pointFlashAnimActive, setPointFlashAnimActive] = useState(false);
    const history = useHistory();
    const { pathname } = useLocation();

    // Safety mechanism
    const {
        appData: { testPlayerAtKiosk },
    } = useContext(AppContext);
    if (typeof testPlayerAtKiosk.data === "undefined") {
        history.push(pathname.replace("/maze", ""));
        window.location.reload();
    }

    const { dispatchAppData } = useContext(AppContext);
    const {
        appData: {
            testPlayerAtKiosk: {
                data: {
                    data: { noOfKiosksCompleted, totalKiosksForAttraction },
                },
            },
            infoData: {
                data: {
                    data: [element],
                },
            },
        },
    } = useContext(AppContext);
    const { pointsdelta } = element;
    const stamps = GenerateStaps(noOfKiosksCompleted, totalKiosksForAttraction);
    useEffect(() => {
        dispatchAppData({
            inMaze: true,
            currentMazeSpot: noOfKiosksCompleted,
            mazeSpotsCount: totalKiosksForAttraction,
        });
        if (noOfKiosksCompleted != totalKiosksForAttraction) {
            setPointFlashAnimActive(false);

            setTimeout(() => {
                setPointFlashAnimActive(true);
                setAnimTrigger("?x=" + Math.random());
            }, 1400);
            setTimeout(() => {
                dispatchAppData({ mazeGifTrigger: true });
            }, 2100);
            setTimeout(() => {
                history.push(
                    pathname.replace("/maze", "") + "/progress-bar-test"
                );
            }, 2800);
        } else {
            setTimeout(() => {
                setAllPointsCollected(true);
            }, 2200);
            setTimeout(() => {
                dispatchAppData({ gifTrigger: true });
            }, 2600);
            setTimeout(() => {
                history.push(
                    pathname.replace("/maze", "") + "/progress-bar-test"
                );
            }, 2700);
        }
    }, []);
    return {
        noOfKiosksCompleted,
        stamps,
        pointsdelta,
        animTrigger,
        pointFlashAnimActive,
        allPointsCollected,
        totalKiosksForAttraction,
    };
};

export default useMaze;
