import styled from "styled-components";
import { IScreenWrapper } from "./types";

const Styles = {
    ScreenWrapper: styled.div<IScreenWrapper>`
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100vw;
        height: 100vh;
        max-width: ${({ isVertical }) => isVertical === true ? `100vw` : `calc(100vh * 1.78);`}; 
        max-height: ${({ isVertical }) => isVertical === true ? `100vh` : `calc(100vw * 0.5625);`}; 
    `,
    Content: styled.div`
        width: 100vw;
        height: 100vh;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
    `,
};

export default Styles;
