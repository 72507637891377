import React from "react";
import ScreenFrame from "../../templates/ScreenFrame";
import VideoPlayer from "../../templates/VideoPlayer";
import parkEnterScanBandVideo from '../../../data/assets/videos/park-enter-scan-band.mp4'

export default function ParkEnterIdle (){

    return ( 
        <ScreenFrame>
            <VideoPlayer source={parkEnterScanBandVideo} autoPlay loop={true} muted controls={false}/>        
        </ScreenFrame>
    )
}