import { useContext } from "react";
import { useLocation } from "react-router";
import { AppContext } from "../../../App";
import Styles from "./styled";
import ImagesData from "../../../../data/assets/images/images";
import { BarContext } from "../../../Screens/PlayerProgressBarTest";

const { Placeholder, Background, AnimatedHeader, PointsIcon } = Styles;
const { topNavBackground, diamond } = ImagesData;

const TopNav = () => {
    const { pathname } = useLocation();

    const {
        barData: {
            headerTextAnimationActive,
            headerSecondTextAnimationActive,
            headerThirdTextAnimationActive,
            headerNextLevelAnimationActive,
        },
    } = useContext(BarContext);

    const {
        appData: {
            testPlayerAtKiosk: {
                data: {
                    data: { pointsForNextToken, PlayerTokens },
                },
            },
        },
    } = useContext(AppContext);

    return (
        <Placeholder>
            <Background src={topNavBackground} />
            {headerTextAnimationActive === true && (
                <AnimatedHeader animationType={"super-short"}>
                    BRAWO!
                </AnimatedHeader>
            )}
            {headerSecondTextAnimationActive === true && (
                <AnimatedHeader animationType={"short"}>
                    NAGRODA JEST BLISKO
                </AnimatedHeader>
            )}
            {headerThirdTextAnimationActive === true && (
                <AnimatedHeader animationType={"long"}>BRAWO!</AnimatedHeader>
            )}
            {headerNextLevelAnimationActive === true && (
                <AnimatedHeader animationType={"super-short"}>
                    LEVEL UP!
                </AnimatedHeader>
            )}
            {pathname.includes("come-back-counter") && (
                <AnimatedHeader animationType={"timer"}>
                    JUŻ TU BYŁEŚ
                </AnimatedHeader>
            )}
            {pathname.includes("maze") && (
                <AnimatedHeader animationType={"short"}>
                    WOW, ODKRYŁEŚ SPOT!
                </AnimatedHeader>
            )}
            {(pathname.includes("token-box") || pathname.includes("gate")) && (
                <>
                    <AnimatedHeader animationType={"short"}>
                        {" "}
                        {parseInt(PlayerTokens) > 0 ? (
                            "SUPER!"
                        ) : (
                            <>
                                {"BRAKUJE CI " + pointsForNextToken}{" "}
                                <PointsIcon src={diamond} />
                            </>
                        )}
                    </AnimatedHeader>
                    <AnimatedHeader animationType={"delay"}>
                        {" "}
                        {parseInt(PlayerTokens) > 0
                            ? "BAW SIĘ DOBRZE!"
                            : "GRAJ DALEJ!"}
                    </AnimatedHeader>
                </>
            )}
        </Placeholder>
    );
};

export default TopNav;
