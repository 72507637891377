import { keyframes } from "styled-components";
import { IAddPointsToBar } from "./types";

const pointsAnim = keyframes`
    0%{
        transform: scale3d(0,0,0);
        opacity: 0;
    }
    85%{
   transform: scale3d(0,0,0);
   opacity: 0;
    }
    100%{
        transform: scale3d(1,1,1);
        opacity: 1;
    }
`;

const mergeStampAnim = keyframes`
    100%{
        left: 42%;
        top: 41%;
    }
`;
const addPointsToBar = ({ leftDest, topDest }: IAddPointsToBar) => keyframes`
    0%{
        opacity:1;
    }
    70%{
        opacity: 1
    }
    100%{
        left: ${leftDest};
        top: ${topDest};
        opacity: 0;
    }
`;

export { pointsAnim, mergeStampAnim, addPointsToBar };
