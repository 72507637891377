import React from "react";
import ImagesData from "../../../../data/assets/images/images";
import Styles from "../styles";

const { ImageWithSize, ImagePosition, PointsText } = Styles;

const { nexusHeroIcon, bigDiamond } = ImagesData;

interface Props {
    missingPoints: number;
}

const MissingPoints = (props: Props) => {
    return (
        <React.Fragment>
            <ImageWithSize src={nexusHeroIcon} size={27} />
            <ImagePosition src={bigDiamond} right={63} top={36} size={9} />
            {/* HERE POINTS MUST COME FROM API */}
            <PointsText fontSize={8} right={72} top={37}>
                {props.missingPoints}
            </PointsText>
            <PointsText fontSize={4} right={5} top={35}>
                Aby uwolnić
            </PointsText>
            {/* HERE POINTS MUST COME FROM API */}
            <PointsText fontSize={6} right={5} top={44}>
                NEXUSA!
            </PointsText>
        </React.Fragment>
    );
};

export default MissingPoints;
