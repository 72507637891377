import ImagesData from "../../../../../data/assets/images/images";
import { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router";
import { AppContext } from "../../../../App/";
import { BarContext } from "../../../../Screens/PlayerProgressBarTest";
import { GetLevelName } from "../../../../../data/levelNames";
import { avatarTable } from "./data";

const { GetLevelIcon } = ImagesData;

const usePlayerStats = () => {
    const {
        barData: {
            startPktAmount,
            coinRewardFlashActive,
            coinRewardTrigger,
            coinsLeftToGive,
            nextLevelTrigger,
        },
    } = useContext(BarContext);

    const {
        appData: {
            finishPoints,
            playerLevel,
            reward,
            levels,
            infoData: {
                data: {
                    data: [element],
                },
            },
            testPlayerAtKiosk: {
                data: {
                    data: { PlayerNickName, PlayerTokens },
                },
            },
        },
    } = useContext(AppContext);

    const [level, setLevel] = useState(playerLevel);
    const [coinsToGive] = useState(
        parseInt(PlayerTokens) - coinsLeftToGive < 0
            ? 0
            : parseInt(PlayerTokens) - coinsLeftToGive
    );

    useEffect(() => {
        if (nextLevelTrigger) {
            setLevel(playerLevel);
        } else {
            let tempLevel = 0;
            if (levels.data.data) {
                for (const level of levels.data.data) {
                    if (finishPoints < parseInt(level.pointsrequired)) {
                        break;
                    }
                    tempLevel = parseInt(level.id);
                }
            }
            const currentPlayerLevel =
                tempLevel > playerLevel ? playerLevel + 1 : playerLevel;
            setLevel(currentPlayerLevel);
        }
    }, [finishPoints, nextLevelTrigger]);

    const { pointsdelta, heroName } = element;
    const { pathname } = useLocation();
    const levelIcon = GetLevelIcon(level, true);
    const currentLevelName = GetLevelName(level);

    const [pointsToDisplay, setPointsToDisplay] = useState<any>();

    useEffect(() => {
        setPointsToDisplay(startPktAmount);
    }, [startPktAmount]);

    useEffect(() => {
        if (pathname.includes("progress-bar-test")) {
            setPointsToDisplay(startPktAmount);
        } else if (pathname.includes("maze")) {
            setPointsToDisplay(finishPoints - pointsdelta);
        } else {
            setPointsToDisplay(finishPoints);
        }
    }, []);


    const hero = avatarTable[heroName];

    return {
        pointsToDisplay,
        PlayerTokens,
        coinRewardFlashActive,
        coinsToGive,
        currentLevelName,
        reward,
        coinRewardTrigger,
        levelIcon,
        PlayerNickName,
        hero,
    };
};

export default usePlayerStats;
