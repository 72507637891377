import { useEffect, useContext } from "react";
import { useLocation, useHistory } from "react-router";
import { AppContext } from "../../../App";
import useFetchData from "../../../../api/apiHelpers";
import api from "../../../../api/api";

const useErrorVertical = (pathnameType: string) => {
    const handler = useFetchData();
    let id = pathnameType.substring(1, 6);
    let type = pathnameType.slice(6);
    const { dispatchAppData } = useContext(AppContext);
    const history = useHistory();
    const { pathname } = useLocation();

    useEffect(() => {
        if (type === "/band-error-vertical") {
            setTimeout(() => {
                dispatchAppData({ gifTrigger: true });
            }, 3800);
            setTimeout(() => {
                history.push(pathname.replace("/band-error-vertical", ""));
            }, 4000);
        } else if (type === "/kiosk-error-vertical") {
            const connectAgain = setInterval(() => {     
                handler(async () => {
                    const response = await api.login(id);
                    if (response.status === 200) {
                        dispatchAppData({ loginData: response });
                        dispatchAppData({ networkError: false });
                        clearInterval(connectAgain);
                        setTimeout(() => {
                            dispatchAppData({ gifTrigger: true });
                        }, 1000);
                        setTimeout(() => {
                            history.push(
                                pathname.replace("/kiosk-error-vertical", "")
                            );
                        }, 1100);
                        dispatchAppData({ cardAtKioskResponding: false });
                    }
                });
            }, 20000);
        }
    }, []);

    return {};
};

export default useErrorVertical;
