import ImagesData from "../../../data/assets/images/images";

const {
    gradientRectangle, xMassRectangle
} = ImagesData;

const backgroundsLookupTable:{[key:string]: string | null} = {
    "1": gradientRectangle,
    "2": gradientRectangle,
    "3": gradientRectangle,
    "4": gradientRectangle,
    "5": gradientRectangle,
    "6": gradientRectangle,
    "7": gradientRectangle,
    "8": gradientRectangle,
    "10": gradientRectangle,
    "12": gradientRectangle,
    "13": gradientRectangle,
    "14": gradientRectangle,
    "15": gradientRectangle,
    "16": gradientRectangle,
    "17": gradientRectangle,
    "19": gradientRectangle,
    "20": gradientRectangle,
    "21": xMassRectangle,
    "22": xMassRectangle,
    "23": xMassRectangle,
    "24": xMassRectangle,
};

export { backgroundsLookupTable};

/*

GKK
01 - boisko multisportowe 11,8,4
02 - arcade games 6,13,5

06 - wyscigi laziki 6,13,5

13 - wulkan 11,8,4
14 - zjzzdzalnie rownolegle 11,8,4

17 - tor przeszkod mini-ninja 11,8,29
18 - tor przeszkod mini-ninja 11,8,4
19 - strzelnia dzialek - bitwa 9,30,26

21 - ZJEŻDŻALNIE + WIEŻA DO WSPINANIA 11,8,4 
22 - ZJEŻDŻALNIE + WIEŻA DO WSPINANIA 11,8,4 
23 - ZJEŻDŻALNIE + WIEŻA DO WSPINANIA 11,8,4 

26 - wejscie 9,14,4
27 - zderzacze 6,13,5
28 - zjazd z antresoli 11,8,29
29 - dmuchany tor przeszkod 11,8,29
30 - skok na poduche 11,8,29
28 - zjazd z antresoli

*/
