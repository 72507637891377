import styled from "styled-components";

import { iconAnimation, levelNameAnim } from "./keyframes";

export const Styles = {
    Wrapper: styled.div`
        position: absolute;
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        z-index: 90;
    `,
    LevelAnim: styled.div`
        position: absolute;
        & > img {
            width: auto;
        }
    `,
    IconAnim: styled.div`
        position: absolute;
        animation: ${iconAnimation} 2s ease-in-out;
        display: flex;
        top: 27%;
        justify-content: center;
        & > img {
            width: 90%;
        }
    `,
    LevelNumberAnim: styled.div`
        position: absolute;
        animation: ${levelNameAnim} 1s ease-in-out;
        top: 5%;
        & > img {
            width: auto;
        }
    `,
    LevelNameAnim: styled.div`
        position: absolute;
        animation: ${levelNameAnim} 1s ease-in-out;
        display: flex;
        justify-content: center;
        bottom: 4%;
        & > img {
            width: 70%;
        }
    `,
};
