import React from "react";
import ImagesData from "../../../../data/assets/images/images";
import DoubleNav from "../../../templates/DoubleNav";
import Styles from "./styles";

const { TokenImg } = Styles;
const { tokenFail } = ImagesData;

const GateClosed = () => {
    return (
        <>
            <DoubleNav />
            <TokenImg src={tokenFail} />
        </>
    );
};

export default GateClosed;
