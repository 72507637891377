import React from "react";
import { useLocation } from "react-router";
import ScreenFrame from "../../templates/ScreenFrame";
import ScreenBackground from "../../templates/ScreenBackground";
import ImagesData from "../../../data/assets/images/images";
import Styles from "./styled";
import useAnimatorAtKiosk from "./hook";

const {
    Frame,
    Shadow,
    TitleWrapper,
    ScreenTitle,
    ScreenName,
    ScreenNameWrapper,
    Star,
    AnimatorIconWrapper,
} = Styles;

const {
    frame,
    shadow,
    logoCMK,
    starfish,
    AnimatorAtKioskText,
    AnimatorAtKiosk,
    AnimatorAwayText,
    AnimatorAway,
} = ImagesData;

const AnimatorAtKioskScreen = () => {
    const { pathname } = useLocation();
    useAnimatorAtKiosk(pathname);
    return (
        <ScreenFrame>
            <ScreenBackground />
            <Frame src={frame} />
            <Shadow src={shadow} />
            <Star src={starfish} />
            <AnimatorIconWrapper>
                {pathname.includes("/animator-at-kiosk") && (
                    <ScreenTitle src={AnimatorAtKiosk} />
                )}
                {pathname.includes("/animator-away") && (
                    <ScreenTitle src={AnimatorAway} />
                )}
            </AnimatorIconWrapper>
            <TitleWrapper>
                {pathname.includes("/animator-at-kiosk") && (
                    <ScreenTitle src={AnimatorAtKioskText} />
                )}
                {pathname.includes("/animator-away") && (
                    <ScreenTitle src={AnimatorAwayText} />
                )}
            </TitleWrapper>
            <ScreenNameWrapper>
                <ScreenName src={logoCMK} />
            </ScreenNameWrapper>
        </ScreenFrame>
    );
};

export default AnimatorAtKioskScreen;
