import styled from "styled-components";

const Styles = {
    KioskWrapper: styled.div`
        position: absolute;
        display: flex;
        justify-content: center;
        width: 100%;
        z-index: 25;
    `,
    Kiosk: styled.img`
        width: 20%;
    `,
    Star: styled.img`
        position: absolute;
        width: 35%;
        z-index: 20;
    `,
    ContentWrapper: styled.div`
        display: flex;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        position: absolute;
        justify-content: center;
        align-items: center;
        z-index: 20;
    `,
};

export default Styles;
