import { mazeFlashImages } from "../../../data/assets/images/mazeScreenFlash";

export const mazeFlashSpots11: {[key: string]: {
     pos:string, left: string, top: string
}} = {
    1: { pos: "left-top", left: "6%", top: "3%" }, //done
    2: { pos: "left-top", left: "20%", top: "3%" }, //done
    3: { pos: "left-top", left: "34%", top: "3%" }, //done
    4: { pos: "right-top", left: "-32%", top: "3%" }, //chyba done
    5: { pos: "right-top", left: "-18%", top: "3%" }, //done
    6: { pos: "right-top", left: "-4%", top: "3%" }, //done
    7: { pos: "left-bottom", left: "12%", top: "-14%" }, //done
    8: { pos: "left-bottom", left: "26%", top: "-14%" }, //done
    9: { pos: "left-bottom", left: "40%", top: "-14%" }, //done
    10: { pos: "right-bottom", left: "-25%", top: "-14%" }, //done
    11: { pos: "right-bottom", left: "-11%", top: "-14%" },
};

export const mazeFlashSpots12: {[key: string]: {
    pos:string, left: string, top: string
}} = {
    1: { pos: "left-top", left: "6%", top: "3%" }, //done
    2: { pos: "left-top", left: "20%", top: "3%" }, //done
    3: { pos: "left-top", left: "34%", top: "3%" }, //done
    4: { pos: "right-top", left: "-32%", top: "3%" }, //chyba done
    5: { pos: "right-top", left: "-18%", top: "3%" }, //done
    6: { pos: "right-top", left: "-4%", top: "3%" }, //done
    7: { pos: "left-bottom", left: "6%", top: "-14%" }, //done
    8: { pos: "left-bottom", left: "20%", top: "-14%" }, //done
    9: { pos: "left-bottom", left: "34%", top: "-14%" }, //done
    10: { pos: "right-bottom", left: "-32%", top: "-14%" }, //done
    11: { pos: "right-bottom", left: "-18%", top: "-14%" },
};


export const mazeFlashElementsData = [
    {
        id: 1,
        delay: "0.04s",
        image: mazeFlashImages.mzf1,
    },
    {
        id: 2,
        delay: "0.08s",
        image: mazeFlashImages.mzf2,
    },
    {
        id: 3,
        delay: "0.12s",
        image: mazeFlashImages.mzf3,
    },
    {
        id: 4,
        delay: "0.16s",
        image: mazeFlashImages.mzf4,
    },
    {
        id: 5,
        delay: "0.20s",
        image: mazeFlashImages.mzf5,
    },
    {
        id: 6,
        delay: "0.24s",
        image: mazeFlashImages.mzf6,
    },
    {
        id: 7,
        delay: "0.28s",
        image: mazeFlashImages.mzf7,
    },
    {
        id: 8,
        delay: "0.32s",
        image: mazeFlashImages.mzf8,
    },
    {
        id: 9,
        delay: "0.36s",
        image: mazeFlashImages.mzf9,
    },
    {
        id: 10,
        delay: "0.40s",
        image: mazeFlashImages.mzf10,
    },
    {
        id: 11,
        delay: "0.44s",
        image: mazeFlashImages.mzf11,
    },
    {
        id: 12,
        delay: "0.48s",
        image: mazeFlashImages.mzf12,
    },
    {
        id: 13,
        delay: "0.52s",
        image: mazeFlashImages.mzf13,
    },
    {
        id: 14,
        delay: "0.56s",
        image: mazeFlashImages.mzf14,
    },
    {
        id: 15,
        delay: "0.60s",
        image: mazeFlashImages.mzf15,
    },
    {
        id: 16,
        delay: "0.64s",
        image: mazeFlashImages.mzf16,
    },
    {
        id: 17,
        delay: "0.68s",
        image: mazeFlashImages.mzf17,
    },
    {
        id: 18,
        delay: "0.72s",
        image: mazeFlashImages.mzf18,
    },
    {
        id: 19,
        delay: "0.76s",
        image: mazeFlashImages.mzf19,
    },
    {
        id: 20,
        delay: "0.80s",
        image: mazeFlashImages.mzf20,
    },
    {
        id: 21,
        delay: "0.84s",
        image: mazeFlashImages.mzf21,
    },
    {
        id: 22,
        delay: "0.88s",
        image: mazeFlashImages.mzf22,
    },
    {
        id: 23,
        delay: "0.92s",
        image: mazeFlashImages.mzf23,
    },
    {
        id: 24,
        delay: "0.96s",
        image: mazeFlashImages.mzf24,
    },
    {
        id: 25,
        delay: "1s",
        image: mazeFlashImages.mzf25,
    },
    {
        id: 26,
        delay: "1.04s",
        image: mazeFlashImages.mzf26,
    },
];

// export const mazeFlashElementsData = [
//     {
//         id: 1,
//         delay: "0.05s",
//         image: mazeFlashImages.mzf1,
//     },
//     {
//         id: 2,
//         delay: "0.10s",
//         image: mazeFlashImages.mzf2,
//     },
//     {
//         id: 3,
//         delay: "0.15s",
//         image: mazeFlashImages.mzf3,
//     },
//     {
//         id: 4,
//         delay: "0.20s",
//         image: mazeFlashImages.mzf4,
//     },
//     {
//         id: 5,
//         delay: "0.25s",
//         image: mazeFlashImages.mzf5,
//     },
//     {
//         id: 6,
//         delay: "0.30s",
//         image: mazeFlashImages.mzf6,
//     },
//     {
//         id: 7,
//         delay: "0.35s",
//         image: mazeFlashImages.mzf7,
//     },
//     {
//         id: 8,
//         delay: "0.40s",
//         image: mazeFlashImages.mzf8,
//     },
//     {
//         id: 9,
//         delay: "0.45s",
//         image: mazeFlashImages.mzf9,
//     },
//     {
//         id: 10,
//         delay: "0.50s",
//         image: mazeFlashImages.mzf10,
//     },
//     {
//         id: 11,
//         delay: "0.55s",
//         image: mazeFlashImages.mzf11,
//     },
//     {
//         id: 12,
//         delay: "0.60s",
//         image: mazeFlashImages.mzf12,
//     },
//     {
//         id: 13,
//         delay: "0.65s",
//         image: mazeFlashImages.mzf13,
//     },
//     {
//         id: 14,
//         delay: "0.70s",
//         image: mazeFlashImages.mzf14,
//     },
//     {
//         id: 15,
//         delay: "0.75s",
//         image: mazeFlashImages.mzf15,
//     },
//     {
//         id: 16,
//         delay: "0.80s",
//         image: mazeFlashImages.mzf16,
//     },
//     {
//         id: 17,
//         delay: "0.85s",
//         image: mazeFlashImages.mzf17,
//     },
//     {
//         id: 18,
//         delay: "0.90s",
//         image: mazeFlashImages.mzf18,
//     },
//     {
//         id: 19,
//         delay: "0.95s",
//         image: mazeFlashImages.mzf19,
//     },
//     {
//         id: 20,
//         delay: "1s",
//         image: mazeFlashImages.mzf20,
//     },
//     {
//         id: 21,
//         delay: "1.05s",
//         image: mazeFlashImages.mzf21,
//     },
//     {
//         id: 22,
//         delay: "1.10s",
//         image: mazeFlashImages.mzf22,
//     },
//     {
//         id: 23,
//         delay: "1.15s",
//         image: mazeFlashImages.mzf23,
//     },
//     {
//         id: 24,
//         delay: "1.20s",
//         image: mazeFlashImages.mzf24,
//     },
//     {
//         id: 25,
//         delay: "1.25s",
//         image: mazeFlashImages.mzf25,
//     },
//     {
//         id: 26,
//         delay: "1.30s",
//         image: mazeFlashImages.mzf26,
//     },
// ];

// export const mazeFlashSpotsIMG11 = {
//     1: { pos: "left-top", right: "22%", bottom: "29%" }, // done
//     2: { pos: "left-top", right: "18%", bottom: "29%" }, // done
//     3: { pos: "left-top", right: "13%", bottom: "29%" }, // done
//     4: { pos: "right-top", right: "54%", bottom: "29%" },
//     5: { pos: "right-top", right: "50%", bottom: "29%" },
//     6: { pos: "right-top", right: "45%", bottom: "29%" }, // done
//     7: { pos: "left-bottom", right: "22%", bottom: "43%" },
//     8: { pos: "left-bottom", right: "18%", bottom: "43%" },
//     9: { pos: "left-bottom", right: "13%", bottom: "43%" },
//     10: { pos: "right-bottom", right: "54%", bottom: "43%" },
//     11: { pos: "right-bottom", right: "50%", bottom: "43%" },
// };

// export const mazeFlashSpotsIMG12 = {
//     1: { pos: "left-top", right: "22%", bottom: "29%" }, // done
//     2: { pos: "left-top", right: "18%", bottom: "29%" }, // done
//     3: { pos: "left-top", right: "13%", bottom: "29%" }, // done
//     4: { pos: "right-top", right: "54%", bottom: "29%" },
//     5: { pos: "right-top", right: "50%", bottom: "29%" },
//     6: { pos: "right-top", right: "45%", bottom: "29%" }, // done
//     7: { pos: "left-bottom", right: "22%", bottom: "43%" },
//     8: { pos: "left-bottom", right: "18%", bottom: "43%" },
//     9: { pos: "left-bottom", right: "13%", bottom: "43%" },
//     10: { pos: "right-bottom", right: "54%", bottom: "43%" },
//     11: { pos: "right-bottom", right: "50%", bottom: "43%" },
//     12: { pos: "right-bottom", right: "45%", bottom: "43%" },
// };
