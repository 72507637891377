import Styles from "./styled";
import ImagesData from "../../../../data/assets/images/images";
import IClock from "./types";

const {
    Time,
    ClockText,
    TimeSecondStroke,
    Placeholder,
    Shadow,
    ClockWrapper,
    ClockImage,
} = Styles;
const { shadow, clock } = ImagesData;

const Clock = ({ timeLeft }: IClock) => {
    return (
        <>
            <Placeholder>
                <ClockText>WRÓĆ ZA:</ClockText>
                <Shadow src={shadow} />
            </Placeholder>
            <ClockWrapper>
                <ClockImage src={clock} />
                <Time>{timeLeft}</Time>
                <TimeSecondStroke>{timeLeft}</TimeSecondStroke>
            </ClockWrapper>
        </>
    );
};

export default Clock;
