import styled from "styled-components";

const Styles = {
    Wrapper: styled.div`
        position: absolute;
        overflow: hidden;
        width: 100%;
        height: 100%;
    `,
};

export default Styles;
