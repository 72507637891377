import React from "react";
import useParkEnter from "./hook";
import "./styles.css"

export default function ParkEnter () {
    const {animationComponents} = useParkEnter()    
    return <div style={{position: 'absolute', zIndex: 2}}>
            <div className="park-enter__animation-element" style={{zIndex:0}}>
                {animationComponents[0]}
            </div>
            {animationComponents.map((El, idx) => idx !== 0 && <div key={idx} className="park-enter__animation-element" style={{zIndex:0}}>
                {El}
            </div>)}
        </div>

}