import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useContext } from "react";
import { AppContext } from "../../App";
import { PlayerLocalData, HeroListType, AnimationDataType } from "./types";
import HeroLoad from "./HeroLoad";
import HeroPresent from "./HeroPresent";
import ParkEntranceDashboard from "./ParkEntranceDashboard";
import cloneDeep from "lodash.clonedeep";
const heroList:HeroListType[] = ['expresso', 'ferven', 'flex', 'nexus', 'scande', 'umbra', "chimeron"]

export default function useParkEnter() {
    
    const [animationData, setAnimationData] = useState<AnimationDataType[]>([])
    const [animationComponents, setAnimationComponents] = useState<JSX.Element[]>([])

    const history = useHistory()
    let time = 0
    const rawAnimationData:AnimationDataType[] = [] 

    // Safety mechanism
    const {
        dispatchAppData,
        appData: { testPlayerAtKiosk:{data: {data: apiData}}},
    } = useContext(AppContext);

    if (typeof apiData === "undefined") {
        history.push("/GKK24", "");
        window.location.reload();
    }

    rawAnimationData.push({
        Component: <HeroLoad />,
        time: 0
    })

    useEffect(() => {   
        let newPlayersState:PlayerLocalData = JSON.parse(localStorage.getItem("players") as string) as PlayerLocalData
        
        if(newPlayersState === null){
            newPlayersState = []
        } 

        const isNoCardNumberInList = !newPlayersState?.some(e => e.id === apiData.CardNumber)

        if(isNoCardNumberInList){
            //there is no player registered with this band number so we register him
            newPlayersState.push({id:apiData.CardNumber, name:apiData.PlayerNickName, hero:heroList[Math.floor(Math.random() * heroList.length)]})
        } 

        const isNewPlayerWithExistingCardNumberInList = newPlayersState?.some(e => e.id === apiData.CardNumber && e.name !== apiData.PlayerNickName)

        if(isNewPlayerWithExistingCardNumberInList){
            //there is already band registered with hero but associated with different player
            newPlayersState = newPlayersState.filter(obj => obj.id !== apiData.CardNumber);
            newPlayersState.push({id:apiData.CardNumber, name:apiData.PlayerNickName, hero:heroList[Math.floor(Math.random() * heroList.length)]})
        } 

        if(isNoCardNumberInList || isNewPlayerWithExistingCardNumberInList) {

            const hero = newPlayersState.find(el => el.id === apiData.CardNumber)

            rawAnimationData.push({
                Component: <HeroPresent hero={hero?.hero as HeroListType || heroList[0]}/>,
                time: time += 2000
            })

            time += 6000
        }

        setTimeout(() => {
            dispatchAppData({ gifTrigger: true })
        }, time += 2350)

        rawAnimationData.push({
            Component: <ParkEntranceDashboard />,
            time: time += 150
        })

        localStorage.setItem("players", JSON.stringify(newPlayersState));

        setAnimationData(rawAnimationData)

        setTimeout(() => {
            dispatchAppData({ gifTrigger: true })
        }, time += 9850)
   // }, time += 985000)

        setTimeout(() => {
            history.push("/GKK24")
        }, time + 150)

    }, [])

    useEffect(() => {

        let composeTimelineTimeout:NodeJS.Timeout
        if(animationData.length > 0) {
            animationData.forEach(el => {
                
                composeTimelineTimeout = setTimeout(() => {
                    setAnimationComponents(state => {
                        const newState = cloneDeep(state)
    
                        // if animation block contains component add it to screen at desired moment
                        if(el?.Component) {
                            const {Component} = el
                            newState?.push(Component)
                        }
            
                        return newState;
                    }) 
                }, el.time)
            })
    
            return () => {
                clearTimeout(composeTimelineTimeout)
            }
        }
    
    }, [animationData])

    return {animationComponents}
}