import { useState, useContext } from "react";
import { AppContext } from "../../../App";
import Styles from "./styled";
import { topTitlesLookupTable, bottomTitlesLookupTable } from "./data";

const { TitleWrapper, ScreenTitle, ScreenNameWrapper, ScreenName } = Styles;

const Title = () => {
    const {
        appData: {
            infoData: {
                data: {
                    data: [element],
                },
            },
        },
    } = useContext(AppContext);

    const { attraction_id } = element;
    const [topScreenTitle] = useState<any>(
  
        topTitlesLookupTable[attraction_id]
    );
    const [bottomScreenTitle] = useState<any>(
       
        bottomTitlesLookupTable[attraction_id]
    );
    return (
        <>
            <TitleWrapper>
                <ScreenTitle src={topScreenTitle} />
            </TitleWrapper>
            <ScreenNameWrapper>
                <ScreenName src={bottomScreenTitle} />
            </ScreenNameWrapper>
        </>
    );
};

export default Title;
