import { useContext, useEffect } from "react";
import { AppContext } from "../../App";
import { useLocation, useHistory } from "react-router";

const useWelcome = () => {
    const { dispatchAppData } = useContext(AppContext);
    const history = useHistory();
    const { pathname } = useLocation();
    useEffect(() => {
        setTimeout(() => {
            dispatchAppData({ gifTrigger: true });
        }, 4800);
        setTimeout(() => {
            history.push(pathname.replace("/welcome", ""));
        }, 5000);
    }, []);

    return {};
};

export default useWelcome;
