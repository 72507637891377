import styled from "styled-components";

const StrokeText = styled.div`
    color: white;
    font-size: 5vw;
    letter-spacing: -0.2vw;
`;

const Styles = {
    TitleWrapper: styled.div`
        top: 3%;
        position: absolute;
        width: 100%;
        height: 15%;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 20;
    `,
    ScreenTitle: styled(StrokeText)`
        z-index: 40;
    `,
    ScreenTitleStrokeOne: styled(StrokeText)`
        position: absolute;
        z-index: 35;
        -webkit-text-stroke-width: 1.2vw;

        -webkit-text-stroke-color: #702283;
        -webkit-text-fill-color: #ffffff;
    `,
    ScreenTitleStrokeTwo: styled(StrokeText)`
        position: absolute;
        z-index: 33;
        -webkit-text-stroke-width: 1.8vw;
        -webkit-text-stroke-color: #d20bab;
        -webkit-text-fill-color: #ffffff;
        text-shadow: 15px 15px 0px rgba(20, 20, 20, 0.5);
    `,
    ScreenNameWrapper: styled.div`
        bottom: 5%;
        position: absolute;
        width: 100%;
        height: 15%;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 20;
    `,
    ScreenName: styled.img`
        width: 25%;
    `,
    NameBackground: styled.img`
        position: absolute;
        top: 100%;
        width: 50%;
    `,
    Name: styled.div`
        position: absolute;
        top: 110%;
        font-size: 3.5vw;
        color: white;
    `,
};

export default Styles;
