import styled from "styled-components";
import { IImageSize, IImagePosition, IPointsText } from "./types";

const Styles = {
    Frame: styled.img`
        position: absolute;
        z-index: 20;
        width: 90%;
        height: 80%;
    `,
    Shadow: styled.img`
        width: 90%;
        height: auto;
        position: absolute;
        left: 5%;
        bottom: 0;
        z-index: 15;
    `,
    Star: styled.img`
        position: absolute;
        width: 35%;
        z-index: 20;
    `,

    TitleWrapper: styled.div`
        top: 1%;
        position: absolute;
        width: 100%;
        height: 18%;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 20;
    `,
    ScreenNameWrapper: styled.div`
        bottom: 5%;
        position: absolute;
        width: 100%;
        height: 15%;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 20;
    `,
    CenterIconWrapper: styled.div`
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 90%;
        height: 80%;
        z-index: 25;
    `,
    ImageWithSize: styled.img<IImageSize>`
        width: ${(props) => props.size}%;
    `,
    PointsText: styled.div<IPointsText>`
        position: absolute;
        font-size: ${(props) => props.fontSize}vw;
        right: ${(props) => props.right}%;
        top: ${(props) => props.top}%;
        color: white;
        text-shadow: 6px 6px 0px rgba(10, 10, 10, 1);
        transform: rotate(-3deg);
    `,
    ImagePosition: styled.img<IImagePosition>`
        position: absolute;
        right: ${(props) => props.right}%;
        top: ${(props) => props.top}%;
        z-index: 60;
        width: ${(props) => (props.size ? props.size : "100")}%;
    `,
    CongratsContainer: styled.div`
        display: flex;
        justify-content: center;
        border: 2px solid black;
        z-index: 80;
    `,
};

export default Styles;
