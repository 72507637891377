import{ useState, useEffect, useContext } from "react";
import { useLocation } from "react-router";
import { AppContext } from "..";
import { screensData, screensRoutesData } from "./screensData";
import IRoutesComponents from "./types";

const useRoutes = () => {
    const [startScreen, setStartScreen] = useState<IRoutesComponents>({
        StartScreen: null,
    });
    const [id, setId] = useState<string>("");
    const { pathname } = useLocation();
    const {
        appData: { infoData },
        dispatchAppData,
    } = useContext(AppContext);
    useEffect(() => {
        setId(pathname.replace("/", ""));
        if (infoData.data) {
            const {
                data: {
                    data: [element],
                },
            } = infoData;

            const { screenroute, successroute, failureroute } = element;
            
            let screenRoutes = screenroute.split(",");
            let successRoutes = successroute.split(",");
            let failureRoutes = failureroute.split(",");

            let StartScreen = screensData[screenRoutes[0]];

            let successRoute = screensRoutesData[successRoutes[0]];
 
            let failureRoute = screensRoutesData[failureRoutes[0]];

            dispatchAppData({ successRoute, failureRoute });

            setStartScreen({
                StartScreen,
            });
        }
    }, [infoData]);

    return { 
        startScreen, id
    };
};

export default useRoutes;
