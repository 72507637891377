import { useContext } from "react";
import Styles from "./styled";
import ImagesData from "../../../../data/assets/images/images";
import { AppContext } from "../../../App";
import { useHistory, useLocation } from "react-router";

const {
    TitleWrapper,
    ScreenTitle,
    ScreenNameWrapper,
    ScreenName,
    ScreenTitleStrokeOne,
    ScreenTitleStrokeTwo,
    NameBackground,
    Name,
} = Styles;
const { logoCMK, nameBackground } = ImagesData;

const Title = () => {
    const history = useHistory();
    const location = useLocation();

    // Safety mechanism
    const {
        appData: { testPlayerAtKiosk },
    } = useContext(AppContext);
    if (typeof testPlayerAtKiosk.data === "undefined") {
        history.push(location.pathname.replace("/welcome", ""));
        window.location.reload();
    }

    const {
        appData: {
            testPlayerAtKiosk: {
                data: {
                    data: { LevelName, PlayerNickName },
                },
            },
        },
    } = useContext(AppContext);

    return (
        <>
            <TitleWrapper>
                <ScreenTitle>WITAJ {LevelName}:</ScreenTitle>
                <ScreenTitleStrokeOne>WITAJ {LevelName}:</ScreenTitleStrokeOne>
                <ScreenTitleStrokeTwo>WITAJ {LevelName}:</ScreenTitleStrokeTwo>
                <NameBackground src={nameBackground} />
                <Name>{PlayerNickName}</Name>
            </TitleWrapper>
            <ScreenNameWrapper>
                <ScreenName src={logoCMK} />
            </ScreenNameWrapper>
        </>
    );
};

export default Title;
