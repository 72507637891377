import ImagesData from "../../../../data/assets/images/images";

const {
    cave,
    logoCMK,
    scanBand,
    getTokenText,
    sports,
    battle,
    cosmicDisco,
  //  cosmicZoo,
    maze,
  //  miniPlanet,
    demolish,
    octopus,
    jumps,
    tornado,
    volcano,
    race,
    rally,
    ninja,
    xmass
} = ImagesData;

const topTitlesLookupTable:{[key:string]: string | null} = {
    "1": scanBand,
    "2": scanBand,
    "3": scanBand,
    "4": scanBand, ///labirynt
    "5": scanBand,
    "6": scanBand,
    "7": scanBand,
    "8": scanBand,
    "10": scanBand,
    "12": scanBand,
    "13": getTokenText,
    "14": scanBand,
    "15": getTokenText,
    "16": getTokenText,
    "17": scanBand,
    "19": scanBand,
    "20": null,

    "21": scanBand,
    "22": scanBand,
    "23": scanBand,
    "24": scanBand,
};

const bottomTitlesLookupTable:{[key:string]: string | null} = {
    "1": octopus,
    "2": rally,
    "3": volcano,
    "4": maze,
    "5": sports,
    "6": cosmicDisco,
    "7": ninja,
    "8": ninja,
    "10": jumps,
    "12": tornado,
    "13": race,
    "14": demolish,
    "15": cave,
    "16": cave,
    "17": battle,
    "19": logoCMK,
    "20": null,

    "21": xmass,
    "22": xmass,
    "23": xmass,
    "24": xmass,
};

export { topTitlesLookupTable, bottomTitlesLookupTable};

/*

GKK
01 - boisko multisportowe 11,8,4
02 - arcade games 6,13,5

06 - wyscigi laziki 6,13,5

13 - wulkan 11,8,4
14 - zjzzdzalnie rownolegle 11,8,4

17 - tor przeszkod mini-ninja 11,8,29
18 - tor przeszkod mini-ninja 11,8,4
19 - strzelnia dzialek - bitwa 9,30,26

21 - ZJEŻDŻALNIE + WIEŻA DO WSPINANIA 11,8,4 
22 - ZJEŻDŻALNIE + WIEŻA DO WSPINANIA 11,8,4 
23 - ZJEŻDŻALNIE + WIEŻA DO WSPINANIA 11,8,4 

26 - wejscie 9,14,4
27 - zderzacze 6,13,5
28 - zjazd z antresoli 11,8,29
29 - dmuchany tor przeszkod 11,8,29
30 - skok na poduche 11,8,29
28 - zjazd z antresoli

*/
