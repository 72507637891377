import { useLocation } from "react-router";
import { Helmet } from "react-helmet";

const Head = () => {
    const { pathname } = useLocation();
    let gkk = pathname.slice(pathname.length - 2, pathname.length);
    return (
        <Helmet>
            <meta charSet="utf-8" />
            <title>GOKIDZ APP</title>
            {pathname === "/" && <link rel="manifest" href="/manifest.json" />}

            {pathname === "/GKK" + gkk && (
                <link rel="manifest" href={"/gkk" + gkk + "-manifest.json"} />
            )}
        </Helmet>
    );
};

export default Head;
