import React, { useEffect } from "react";
import ImagesData from "../../../../data/assets/images/images";
import ScreenBackground from "../../../templates/ScreenBackground";
import ScreenFrame from "../../../templates/ScreenFrame";
import Styles from "./styles";

const {
    Frame,
    Shadow,
    Star,
    KioskWrapper,
    TitleWrapper,
    ScreenTitle,
    ScreenNameWrapper,
    ScreenName,
} = Styles;

const {
    shadow,
    frame,
    starfish,
    defeatAnotherChallenge,
    brokenKiosk,
    logoCMK,
} = ImagesData;

const ErrorFallback = (props: any) => {
    useEffect(() => {
        localStorage.setItem(Date.now().toString(), props.error);
    }, []);

    return (
        <ScreenFrame>
            <ScreenBackground />
            <Frame src={frame} />
            <Shadow src={shadow} />
            <Star src={starfish} />
            <KioskWrapper>
                <ScreenTitle src={brokenKiosk} />
            </KioskWrapper>
            <TitleWrapper>
                <ScreenTitle src={defeatAnotherChallenge} />
            </TitleWrapper>
            <ScreenNameWrapper>
                <ScreenName src={logoCMK} />
            </ScreenNameWrapper>
        </ScreenFrame>
    );
};

export default ErrorFallback;
