import TopNav from "./TopNav";
import BottomNav from "./BottomNav";
import Styles from "./styled";

const { Wrapper } = Styles;

const DoubleNav = () => {
    return (
        <Wrapper>
            <TopNav />
            <BottomNav />
        </Wrapper>
    );
};

export default DoubleNav;
