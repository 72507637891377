import Styles from "./styled";
import ImagesData from "../../../data/assets/images/images";
import Title from "./Title";
import Content from "./Content";

const { Frame, Shadow } = Styles;
const { frame, shadow } = ImagesData;

const StartFrame = () => {
    return (
        <>
            <Frame src={frame} />
            <Shadow src={shadow} />
            <Title />
            <Content />
        </>
    );
};

export default StartFrame;
