import { useEffect, useContext } from "react";
import { AppContext } from "../../App";
import { useHistory, useLocation } from "react-router";

const useTokenBox = () => {
    const {
        appData: {
            testPlayerAtKiosk: {
                data: {
                    data: { PlayerTokens, group_id },
                },
            },
        },
        dispatchAppData,
    } = useContext(AppContext);

    const { pathname } = useLocation();
    const history = useHistory();

    useEffect(() => {
        if (group_id && parseInt(group_id) > 0) {
            history.push(
                pathname.replace("/token-box", "/birthday-get-tokens")
            );
        } else {
            if (PlayerTokens === "0") {
                setTimeout(() => {
                    dispatchAppData({ gifTrigger: true });
                }, 3800);
                setTimeout(() => {
                    history.push(pathname.replace("/token-box", ""));
                }, 4000);
            } else {
                setTimeout(() => {
                    dispatchAppData({ gifTrigger: true });
                }, 3600);

                setTimeout(() => {
                    history.push(
                        pathname.replace("/token-box", "") + "/token-payer"
                    );
                }, 3700);
            }
        }
    }, []);

    return {};
};

export default useTokenBox;
