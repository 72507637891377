import ScreenBackground from "../../templates/ScreenBackground";
import Hand from "../../templates/Hand";

const StartScanHand = () => {
    return (
        <>
            <ScreenBackground />
            <Hand />
        </>
    );
};

export default StartScanHand;
