import styled from "styled-components";
import { IBarPosition, IBonusPosition, ITransTest } from "./types";

export const Styles = {
    ProgressBarContainer: styled.div`
        display: flex;
        margin-bottom: 1.5%;
        /* border: 2px solid black; */
        /* z-index: 60; */
    `,
    BarBgImage: styled.div`
        width: 100%;
        z-index: 55;
        display: flex;
        justify-content: center;
        overflow: hidden;
        & > img {
            width: 101%;
        }
    `,
    Bar: styled.img<IBarPosition>`
        left: ${(props) => props.left}%;
        width: ${(props) => props.width}%;
        height: 80%;
    `,
    BarsContainer: styled.div<ITransTest>`
        position: absolute;
        display: flex;
        flex-flow: row;
        justify-content: space-between;
        align-items: center;
        height: 100%;
        width: 100%;
        /* border: 2px solid white; */
        z-index: 60;
        transition: ${(props) =>
            props.active ? "clip-path 1.4s ease-in-out" : "none"};
        clip-path: inset(0% ${(props) => props.trans}% 0% 0%);
    `,
    BonusContainer: styled.div`
        position: absolute;
        display: flex;
        flex-flow: row;
        align-items: center;
        width: 100%;
        height: 100%;
    `,
    BonusPrize: styled.img<IBonusPosition>`
        position: absolute;
        left: ${(props) => props.left}%;
        width: ${(props) => (props.width ? props.width : 5)}%;
        z-index: 85;
    `,
};

export default Styles;
