const handleApiErr =
    (fn: Function, callback: Function, finallyCallback: Function) =>
    (...params: any) =>
        fn(...params)
            .catch(callback)
            .finally(finallyCallback);

const useFetchData = () => {
    const handleApi = handleApiErr(
        async (callback: Function) => {
            await callback();
        },
        (e: object) => {
            console.error(e);
            window.location.reload();
        },
        () => {}
    );
    return handleApi;
};

export default useFetchData;
