import styled from "styled-components";

const Styles = {
    Background: styled.img`
        position: fixed;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        z-index: 10;
    `,
    StarsLeft: styled.img`
        position: fixed;
        left: 0px;
        top: -10%;
        z-index: 15;
        width: 50%;
        height: 60%;
    `,
    StarsRight: styled.img`
        position: fixed;
        right: 0px;
        top: -10%;
        z-index: 15;
        width: 50%;
        height: 60%;
    `,
};
export default Styles;
