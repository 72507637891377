import Styles from "./styled";
import ImagesData from "../../../data/assets/images/images";
import { useContext, useState } from "react";
import { AppContext } from "../../App";
import { backgroundsLookupTable } from "./data";


const { Background, StarsLeft, StarsRight } = Styles;
const { stars1, stars2 } = ImagesData;

const ScreenBackground = () => {
    
    const {
        appData
    } = useContext(AppContext);

    const [background] = useState<any>(
        backgroundsLookupTable[appData?.infoData?.data?.data[0].attraction_id]
    );
   
    return (
        <>
            <Background src={background} />
            <StarsLeft src={stars1} />
            <StarsRight src={stars2} />
        </>
    );
};

export default ScreenBackground;
